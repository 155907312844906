import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LineChartEnergyView2Y from "../../containers/graphs/LineChartEnergyView2Y";
import { graphData } from "../../files/sampleData/graphdata";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";
import { GraphUtils } from "../../utils/graphUtils";

const graphUtils = new GraphUtils();

const MonthlyTodCostView = ({
  selectedCostMeterData,
  handleCostGraph,
  selectedCostGraph,
  selectedCostMeter,
  selectedCostYear,
  selectedCostMonth,
  costMonthlyBillingDemand,
  selectedFilterMeter,
  metersList
}) => {
  const constantUtils = new ConstantUtils();
  const [isOpenDropwonView, setIsOpenDropwonView] = useState(false);
  const navigate = useNavigate();
  const [billUrl, setBillUrl] = useState();
  const handleEnergyMenuClick = () => {
    setIsOpenDropwonView(!isOpenDropwonView);
  };
  const billservice = new BillConsumptionService();

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Amount (Rs)", // Add your label here
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
            }
            return value;
          }
        }
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Amount (Rs)", // Add your label here
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });

  useEffect(() => {
    const selectedMeterData = metersList.filter((meter) => meter._id === selectedCostMeter);

    const getDateRange = getMonthDateRange(selectedCostMonth);
if(getDateRange && selectedMeterData && selectedMeterData[0]  && selectedMeterData[0]["consumerNo"]){

  console.log({billUrl:`/compare-ocr-bills?conNo=${selectedMeterData[0]["consumerNo"]}&startDate=${getDateRange['startDate']}&endDate=${getDateRange['endDate']}&redirect=analytics`});
  
  setBillUrl(`/compare-ocr-bills?conNo=${selectedMeterData[0]["consumerNo"]}&startDate=${getDateRange['startDate']}&endDate=${getDateRange['endDate']}&redirect=analytics`);
}
  }, [selectedCostMeter,selectedCostYear,selectedCostMonth])

  const getMonthDateRange = (month) => {
    const year = selectedCostYear;
    console.log({selectedCostYear})
    // Mapping for months to numerical values
    const monthMap = {
      JAN: "01",
      FEB: "02",
      MAR: "03",
      APR: "04",
      MAY: "05",
      JUN: "06",
      JUL: "07",
      AUG: "08",
      SEP: "09",
      OCT: "10",
      NOV: "11",
      DEC: "12"
    };

    // Get the month number from the map
    const monthNumber = monthMap[month.toUpperCase()];

    if (!monthNumber) {
      console.log("Invalid month format. Use the first three letters of the month (e.g., JAN, FEB).");
    }

    return {
      startDate: `${monthNumber}/${year}`,
      endDate: `${monthNumber}/${year}`
    };
  }


  const [labels, setLabels] = useState([]);
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Total cost",
        data: [], // replace with your actual data
        type: "line",
        borderColor: constantUtils.getGraphColors().peakDemand,
        backgroundColor: constantUtils.getGraphColors().peakDemand,
        fill: false,
        yAxisID: "y1",
      },
      {
        label: "Energy variable cost",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().contractDemand,
        backgroundColor: constantUtils.getGraphColors().contractDemand,
        fill: false,
      },
      {
        label: "Rs/KWH",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().percentContractDemand,
        backgroundColor: constantUtils.getGraphColors().percentContractDemand,
        fill: false,
      },
    ],
  });

  useEffect(() => {
    if (data !== undefined && selectedCostMeterData !== undefined) {
      setLabels(selectedCostMeterData['updatedLabels'])
    }
  }, [selectedCostMeterData])

  useEffect(() => {
    //    console.log({labels})
    if (labels) {
      setData((prevData) => ({
        ...prevData,
        labels: labels,
      }));
    }
  }, [labels]);

  //   useEffect(() => {
  //     // console.log("updating labels")
  //     setLabels(unitTodLabels);
  //   }, [unitTodLabels]);

  useEffect(() => {
    //    console.log({ costMonthlyBillingDemand ,data:"data updated"});
    //    console.log({lebelLEngth:labels.length})
    if (costMonthlyBillingDemand && labels && labels.length > 0) {
      const updatedData = [...data.datasets];
      //   console.log({updatedData})
      updatedData.forEach((dataset) => {
        if (dataset.label === "Total cost") {
          dataset.data = Array(labels.length).fill(
            costMonthlyBillingDemand.totalCost
          );
        } else if (dataset.label === "Energy variable cost") {
          dataset.data = Array(labels.length).fill(
            costMonthlyBillingDemand.energyVariableCost
          );
        } else if (dataset.label === "Rs/KWH") {
          dataset.data = Array(labels.length).fill(
            costMonthlyBillingDemand.RsPerKwh
          );
        }
      });
      //   console.log({updatedData})
      //   setData({ ...data, datasets: updatedData });
    }
  }, [costMonthlyBillingDemand, labels]);

  useEffect(() => {
    if (
      data !== undefined &&
      selectedCostMeterData !== undefined &&
      selectedCostMeterData["cost"] && labels && labels.length > 0
    ) {
      console.log({
        selectedCostMeterData: selectedCostMeterData,
      });
      setData((prevData) => {
        let newDatasets = prevData.datasets
          .map((dataset) => {
            if (dataset && dataset.label === "Total cost") {
              return dataset;
            } else if (dataset && dataset.label === "Energy variable cost") {
              return dataset;
            } else if (dataset && dataset.label === "Rs/KWH") {
              return dataset;
            }
          })
          .filter(Boolean);
        //        console.log({ newDatasets });
        //        console.log({ newDatasets: selectedCostMeterData["cost"] });

        let dynamicDatasets = Array.isArray(selectedCostMeterData["cost"])
          ? selectedCostMeterData["cost"]
          : [];
        //        console.log({ dynamicDatasets });
        newDatasets.push(...dynamicDatasets);

        //        console.log({
        //   ...prevData,
        //   datasets: newDatasets,
        // });
        return {
          ...prevData,
          datasets: newDatasets,
        };
      });
    } else {
      setData((prevData) => {
        let newDatasets = prevData.datasets.filter((dataset) => {
          return dataset.label !== "slots";
        });

        return {
          ...prevData,
          datasets: newDatasets,
        };
      });
    }
  }, [selectedCostMeterData, labels]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log({
    //   useeffectValues: [
    //     selectedCostMeterData,
    //     selectedCostMeterData["meter"],
    //     selectedCostMeter,
    //     selectedCostMeterData.year,
    //     selectedCostYear.toString(),
    //     selectedCostMeterData.month,
    //     selectedCostMonth.toLowerCase(),
    //   ],
    // });
    if (
      (selectedCostMeterData.year === selectedCostYear.toString() &&
        selectedCostMeterData.month === selectedCostMonth.toLowerCase() &&
        selectedCostMeterData["meter"] === selectedCostMeter) || selectedCostMeterData.length === 0
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
    }
  }, [
    selectedCostMeterData,
    selectedCostYear,
    selectedCostMonth,
    selectedCostMeter,
  ]);

  const [full, setFull] = useState(false);
  const dropdownRefCostTod = useRef(null); // Add this line

  //   useEffect(() => {
  //     const selectedMeterUnit = metersList.find(
  //       (meterData) => meterData._id === selectedCostMeter
  //     );
  // //    console.log({
  //     //   metersList,
  //     //   selectedCostMeter,
  //     //   selectedMeterUnit: selectedMeterUnit["unitId"]["_id"],
  //     // });
  //     const selectdUnitId=selectedMeterUnit["unitId"]["_id"];
  //     if (selectdUnitId) {
  //       setSelectedUnit(selectdUnitId);
  //     }
  //             const existingUnitData = slotnames.find(slot => selectdUnitId in slot);
  //         if(existingUnitData && existingUnitData[selectdUnitId] !== undefined ){
  // //            console.log({existingUnitData:existingUnitData[selectedUnit]});
  //             setSlotNamesUpdated(existingUnitData[selectdUnitId]);
  //             const MyLabels = getLabels(existingUnitData[selectdUnitId], slotTimelables[selectdUnitId])
  //             console.log({MyLabels})
  //             // setLabels(getLabels(existingUnitData[selectedUnit], slotTimelables[selectedUnit]));
  //         }
  //   }, [selectedCostMeter]);

  useEffect(() => {
    // console.log({data})
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefCostTod.current &&
        !dropdownRefCostTod.current.contains(event.target)
      ) {
        setIsOpenDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`${selectedCostGraph === "TOD" ? "flex" : "hidden"
        }  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040] mb-5 sm:mb-0 sm:min-h-[400px] rounded-[30px] lg:w-[50%]`}
    >
      <div className={`px-[20px] sm:px-[35px] `}>
        <div
          className={` flex flex-row justify-between px-[20px] sm:px-[35px] py-[10px] sm:py-[25px] w-full`}
        >
          <div>Monthly : ToD Cost Distribution : Discom </div>
          <div className="cursor-pointer flex sm:min-w-[250px]  items-center justify-between">
            <div className="mx-1 flex gap-2">
              <button
                onClick={() =>
                  handleCostGraph(
                    "BillDistrubutionView",
                    selectedCostYear,
                    selectedCostMonth
                  )
                }
                className="py-0 px-1 text-[12px] md:text-sm bg-gray-400 rounded-md"
              >
                Bill  Distribution
              </button>
              <div onClick={()=>navigate(billUrl)} className="bg-green-700 text-[12px] py-1 px-2  text-white rounded-lg">
               View Bill
              </div>
            </div>
            <div className="cursor-pointer flex gap-[20px]">
              <button onClick={() => setFull(true)}>
                <FontAwesomeIcon icon={faExpand} />
              </button>
              <CustomTooltop
                disable={
                  constantUtils.getGraphToolTipData().MonthlyTodCost
                    .disableToolTip
                }
                text={constantUtils.getGraphToolTipData().MonthlyTodCost.text}
              />
            </div>
            {/*     <img
              onClick={handleEnergyMenuClick}
              alt="menuIcon"
              src="/graphMenuIcon.svg"
            />
            {isOpenDropwonView && (
              <div className="absolute right-[1%]">
                {isOpenDropwonView && (
                  <div className={`absolute  pt-[35px]`}>
                    <ul
                      className={` text-[16px] rounded-[10px] bg-white  font-medium leading-[24px] border py-[10px] px-[10px] shadow-[0_4px_4px_0_#00000040] ml-[-380px] border-[#D9D9D9]`}
                    >
                      <li
                        onClick={() =>
                          handleCostGraph(
                            "Annual",
                            selectedCostYear,
                            selectedCostMonth
                          )
                        }
                        className="inline-flex cursor-pointer gap-[10px]"
                      >
                        <img alt="left" src="/leftCircleArrow.svg" />
                        <span>Annual Power Consumption View (KWH)</span>{" "}
                      </li>
                      <li
                        onClick={() =>
                          handleCostGraph(
                            "Monthly",
                            selectedCostYear,
                            selectedCostMonth
                          )
                        }
                        className="inline-flex cursor-pointer gap-[10px]"
                      >
                        <img alt="left" src="/leftCircleArrow.svg" />
                        <span>Monthly Power Consumption View (KWH)</span>{" "}
                      </li>
                    </ul>
                  </div>
                )} 
              </div>
            )}*/}
          </div>
        </div>
        <div>
          <LegendComponent
            data={data}
            position={"place-items-start"}
            columns={"grid-cols-4"}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="flex justify-end px-10"> */}
          {/* <select
              onChange={handleYear}
              value={selectedCostYear}
              className="px-2"
            >
              {annualData.years.map((meter) => (
                <option className="capitalize" key={meter} value={meter}>
                  {meter}
                </option>
              ))}
            </select>
            <select
              onChange={handleMonth}
              className="px-2 uppercase"
              value={selectedMonth}
            >
              {monthss.map((meter) => (
                <option className=" " key={meter} value={meter}>
                  {meter}
                </option>
              ))}
            </select>
            <select
              onChange={handleMeter}
              value={selectedCostMeter.meterNo}
              className="px-2 uppercase"
            >
              {metersList.map((meter) => (
                <option key={meter} value={meter._id}>
                  {meter.meterNo}
                </option>
              ))}
            </select> */}
          {/* </div> */}

          <div className="sm:px-[35px] pb-[20px] sm:py-[25px]">
            {selectedCostMeterData.year === "" &&
              selectedCostMeterData.month === "" ? (
              <div className="w-full flex justify-center items-center min-h-[300px]">
                Data Not Found
              </div>
            ) : (
              <LineChartEnergyView2Y
                data={data}
                options={options}
                full={full}
                setFull={setFull}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MonthlyTodCostView;
