import { faCircleChevronLeft, faDashboard, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const GoTODashboard = ({text}) => {
    const navigate = useNavigate();

    return (
      <div
        onClick={() => navigate("/")}
        className="flex border border-gray-300 shadow-lg bg-[#067C4E] p-1 sm:p-2 rounded-md justify-center cursor-pointer items-center gap-2"
      >
        <FontAwesomeIcon color='white' size="lg" icon={faHouse} />
        <span className='text-xs text-white'>{text?text: "Go To DashBoard"}</span>
  
      </div>
    );
}
export default GoTODashboard