import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

const LegendComponent = ({ data, position, columns,slotConfigData }) => {
  const [uniqueArray, setUniqueArray] = useState([]);

  useEffect(() => {
    if (data && data.datasets) {
      let uniqueArrayResult = data.datasets.filter(
        (v, i, a) => a.map((e) => e.label).indexOf(v.label) === i
      );
      // console.log({uniqueArrayResult})
      setUniqueArray(uniqueArrayResult);
    }
  }, [data]);

  const [tooltipId, setTooltipId] = useState("");


  const getToolTioId=()=>{
    setTooltipId(`my-tooltip-${Math.random().toString(36).substr(2, 9)}`);
    return `my-tooltip-${Math.random().toString(36).substr(2, 9)}`
  }

  useEffect(() => {
    // console.log({slotConfigData})
  }, [slotConfigData])
  

 const  getTimeSlotString = (ToD, slotConfig) => {
    // console.log({ToD, slotConfig})
    const slotMap = {
        "ToD-1": "slot-1",
        "ToD-2": "slot-2",
        "ToD-3": "slot-3",
        "ToD-4": "slot-4",
        "ToD-5": "slot-5",
        "ToD-6": "slot-6",
        "RTC": "RTC",
        
    };
    
    const slotKey = slotMap[ToD];
    if(slotKey === "RTC"){
        return "RTC : Time: 00:00 - 24:00";
    }
    
    if (!slotKey || !slotConfig || !slotConfig["slot"][slotKey]) {
        return "";
    }



    const timeSlots = slotConfig["slot"][slotKey];
    // console.log({string:`${ToD}: ${timeSlots.map(time => `Time: ${time}`).join(', ')}`})
    
    return `${ToD}: ${timeSlots.map(time => `Time: ${time}`).join(', ')}`;
}

 

  return (
    <div
      className={`md:flex md:flex-row md:justify-center min-h-12 items-center grid ${
        columns ? columns : "grid-cols-2"
      }  py-2  ${position ? position : "place-items-center"}   w-full`}
    >
      {uniqueArray.map((label) =>
        label.type === "line" ? (
          <div
            key={label.label}
            className="flex justify-between  items-center mx-1 rounded-md  "
          >
            <div
              style={{ backgroundColor: label.backgroundColor }}
              className={`absolute   w-[6px] h-[6px] rounded-full`}
            ></div>
            <div
              style={{ backgroundColor: label.backgroundColor }}
              className="w-[15px] md:w-[20px]  min-h-[2px]"
            ></div>
            <div
              style={{ backgroundColor: label.backgroundColor }}
              className=" absolute ml-[10px]  md:ml-[20px]  w-[6px] h-[6px] rounded-full"
            ></div>
            <div className={`text-[10px] md:text-[12px] ml-2 md:ml-2`}>
              {label.label}
            </div>
          </div>
        ) : (
          <div
            key={label.label}
            data-tooltip-id={getTimeSlotString(label.label,slotConfigData) || ""}
            className="flex items-center mx-1 rounded-md gap-1  "
          >
            <div>
              <FontAwesomeIcon
                color={label.backgroundColor}
                icon={faChartSimple}
                size="xs"
              />
            </div>
            <div className={`text-[10px] md:text-[12px]`}>{label.label}</div>
            <Tooltip
              style={{
                backgroundColor: "#90EE90",
                color: "#222",
                zIndex: "41",
              }}
              id={getTimeSlotString(label.label,slotConfigData)|| "ddsfds"}
            >
              <div
                className="z-50"
                style={{
                  display: "flex",
                  maxWidth: "220px",
                  flexDirection: "column",
                }}
              >
                {getTimeSlotString(label.label,slotConfigData) || "ddsfds"}
              </div>
            </Tooltip>
          </div>
        )
      )}
    </div>
  );
};

export default LegendComponent;
