import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";
import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useNavigation,
} from "react-router-dom";
import { postMediaList, uploadExcelData } from "../../api/uploadFile";
import DatePicker from "react-datepicker";
// import { field } from '../../utils/BillUploadUtils';
// import { fieldNames } from '../../utils/BillUploadUtils';
// import { units } from '../../utils/BillUploadUtils';
import { useJobProgress } from "../../hooks/billsJob";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import PdfHighlighter from "../../../src/components/pdfTextHighlight";
import BackButton from "../../components/backButton/BackButton";

const ValidateBillsOcrData = () => {
  const {
    jobErrorMessage,
    jobIdLength,
    succesfullJobLength,
    extractKeysAndValues,
    processCompletedJob,
    processJobIds,
    field,
    fieldNames,
    units,
    discomCode,
    setDiscomCode,
    consumerType,
    setConsumerType,
  } = useJobProgress();
  const inputRefs = useRef(
    Array(100)
      .fill(null)
      .map(() => React.createRef())
  );

  const { id } = useParams();
  const [ocrData, setOcrData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [ocrUnchangedData, setOcrUnchangedData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const navigate = useNavigate();
  const [billView, setBillView] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [ocrUpdateData, setOcrUpdateData] = useState({});
  const requiredFields = [
    "meterNo",
    "consumerName",
    "readingDate",
    "previousReadingDate",
    "consumption",
    "totalConsumption",
    "billedDemand",
    "units",
    "rate",
    "totalCurrentBill",
    "totalBillAmount",
    "todSlot",
  ];
  const dateInputs = [
    "billDate",
    "dueDate",
    "dateOfConnection",
    "readingDate",
    "previousReadingDate",
  ];
  const [stateCode, setStateCode] = useState("");
  const [oldOcrData, setOldOcrData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectValue = queryParams.get("redirect");
  // const [discomCode, setDiscomCode] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const ocrDataResponse = await getData(
        `${process.env.REACT_APP_API_URL}/ocr/${id}`
      );
      console.log({ ocrDataResponse });
      if (ocrDataResponse) {
        const data = {
          objectId: ocrDataResponse?.data?._id,
        };
        const media = await postMediaList(
          `${process.env.REACT_APP_API_URL}/media/list`,
          data
        );
        // console.log(media);
        if (initialLoad) {
          setOcrUnchangedData(ocrDataResponse?.data?.data);
        }

        setInitialLoad(false);
        setBillView(media?.data?.results[0]?.url);
        setStateCode(ocrDataResponse.data.data.stateCode);
        setDiscomCode(ocrDataResponse.data.data.discomCode);
        setConsumerType(ocrDataResponse.data.data.industry);
        const extractedData = extractKeysAndValues(ocrDataResponse?.data?.data);
        // console.log(extractedData);
        if (extractedData) {
          setOcrData(extractedData);
          setOldOcrData(extractedData);

          setOcrUpdateData(ocrDataResponse?.data?.data);
          setLoading(false);
        }
      }
    };
    fetchData().catch(console.error);
  }, [id, fieldNames]);

  const updateOcrUpdateData = (fieldName, value) => {
    // Clone the current ocrUpdateData object
    let updatedOcrUpdateData = { ...ocrUpdateData };
    // console.log(fieldName, '===============');
    if (fieldName.startsWith("slot-")) {
      // Extract slot number and property name from the fieldName
      const parts = fieldName.split("-");
      const slotNumber = parseInt(parts[1]); // Extract slot number
      const propertyName = parts[2]; // Extract property name

      // Ensure the todZone array exists in the ocrUpdateData object
      if (!updatedOcrUpdateData.todZone) {
        updatedOcrUpdateData.todZone = [];
      }

      // Ensure the array index is valid
      if (
        slotNumber >= 1 &&
        slotNumber <= updatedOcrUpdateData.todZone.length
      ) {
        // console.log('oldentry');
        // Update the specified property of the object at the specified index
        updatedOcrUpdateData.todZone[slotNumber - 1][
          propertyName === "unit" ? "units" : propertyName
        ] = value;
      } else if (
        updatedOcrUpdateData.todZone.length === 0 ||
        updatedOcrUpdateData.todZone.length === slotNumber - 1
      ) {
        // console.log('newentry');

        while (updatedOcrUpdateData.todZone.length < slotNumber) {
          updatedOcrUpdateData.todZone.push({});
        }

        // Set the property value for the newly added slot
        updatedOcrUpdateData.todZone[slotNumber - 1][
          propertyName === "unit" ? "units" : propertyName
        ] = value;
      }
    } else if (
      fieldName.startsWith("solarGenerationSlot") ||
      fieldName.startsWith("solarExportSlot")
    ) {
      // Check if the field starts with "solarGenerationSlot" or "solarExportSlot"
      // If it does, update the field directly in the updatedOcrUpdateData object
      updatedOcrUpdateData[fieldName].value = value;
    } else if (fieldName === "units" || fieldName === "rate") {
      const category = updatedOcrUpdateData["category"];
      const consumptionTypeObj = updatedOcrUpdateData["consumptionType"]?.find(
        (item) =>
          item["consumption type"]?.toLowerCase() ===
          category.value?.toLowerCase()
      );
      // Check if consumptionTypeObj exists before updating its fieldName property
      if (consumptionTypeObj) {
        consumptionTypeObj[fieldName] = value;
      }
    } else if (typeof updatedOcrUpdateData[fieldName] === "object") {
      updatedOcrUpdateData[fieldName].value = value;
    } else if (fieldName === "averageConsumptionUnit" || fieldName === "greenPowerTariffConsumption" ) {
      // Update the value of the specified field
      updatedOcrUpdateData[fieldName] = {
        value: parseFloat(value),
        unit: "kWh",
      };
    }
    else if ( fieldName === "greenPowerTariffCost") {
      // Update the value of the specified field
      updatedOcrUpdateData[fieldName] = {
        value: parseFloat(value),
        unit: "Rs",
      };
    }
    else if ( fieldName === "greenPowerTariffRate") {
      updatedOcrUpdateData[fieldName] = {
        value: parseFloat(value),
        unit: "%",
      };
    } else {
      // Update the value of the specified field
      updatedOcrUpdateData[fieldName] = value;
    }
    console.log(updatedOcrUpdateData, "updatedOcrUpdateData");
    // Set the updated ocrUpdateData object
    setOcrUpdateData(updatedOcrUpdateData);
  };
  // console.log(ocrUpdateData);
  const handleInputChange = (value, index) => {
    if (dateInputs.includes(fieldNames[index])) {
      setOcrData((prevData) => ({
        ...prevData,
        values: prevData.values.map((item, i) => {
          if (i === index) {
            return formatDate(value); // Format the date value before setting it
          }
          return item;
        }),
      }));
    } else {
      setOcrData((prevData) => ({
        ...prevData,
        values: prevData.values.map((item, i) => {
          if (i === index) {
            return value;
          }
          return item;
        }),
      }));
    }

    const error = validateField(fieldNames[index], value, index);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldNames[index]]: error,
    }));
    updateOcrUpdateData(fieldNames[index], value);
    // if (inputRefs.current[index] && inputRefs.current[index].current) {
    // console.log(value, index);
    // inputRefs.current[index].current.focus();
    getSelectedText(value, index);
    // }
  };

  const validateField = (field, value) => {
    let error = "";
    if (
      field === "readingDate" ||
      field === "previousReadingDate" ||
      field === "meterNo" ||
      field === "consumerName" ||
      field === "consumption" ||
      field === "totalConsumption" ||
      field === "billedDemand" ||
      field === "rate" ||
      field === "totalCurrentBill" ||
      field === "todSlot" ||
      field === "email"
    ) {
      if (!value) error = "This field is required";
    }
    return error;
  };

  function areArraysEqual(ocrDataOld, newOcrData) {
    // console.log('vvvv');
    // Check if the arrays have the same length
    // if (ocrDataOld.length !== newOcrData.length) {
    //     return false;
    // }

    // Compare each element at the same index
    for (let i = 0; i < ocrDataOld.length; i++) {
      if (ocrDataOld[i] !== newOcrData[i]) {
        return false;
      }
    }

    // If all elements are equal, return true
    return true;
  }

  const handleSubmit = async () => {
    try {
      if (requiredFields.every((field) => ocrUpdateData[field] !== "")) {
        const isModified = areArraysEqual(oldOcrData.values, ocrData.values);
        console.log({ ocrUpdateData });
        let previousCurrentReadingDate= "";
        if(ocrUnchangedData?.readingDate?.value !== ocrUpdateData?.readingDate?.value){
            previousCurrentReadingDate = ocrUnchangedData?.readingDate?.value
        }
        const data = {
          buyerId: localStorage.getItem("userId"),
          stateCode: stateCode,
          discomCode: discomCode,
          consumerType: consumerType,
          billData: [field, fieldNames, units, ocrData?.values],
          status: isModified ? "VALIDATED" : "MODIFIED",
          billUploadSoruce: "ocr",
          prevCurrentReadingDate: previousCurrentReadingDate
        };

    
        const  response = await uploadExcelData(
                `${process.env.REACT_APP_API_URL}/bill/processRawData`,
                data
              );

        if (response?.statusCode == 201) {
          // ocrUpdateData["status"] = isModified ? "VALIDATED" : "MODIFIED";
          const previousStatus = ocrUpdateData["status"];

          if (previousStatus === "MODIFIED" || !isModified) {
            ocrUpdateData["status"] = "MODIFIED";
          } else {
            ocrUpdateData["status"] = "VALIDATED";
          }

          console.log({ ocrUnchangedData, ocrUpdateData });

          const uploadData = {
            data: {
              ...ocrUpdateData,
            },
          };
          console.log(uploadData, "uploadData");
          const response = await updateData(
            `${process.env.REACT_APP_API_URL}/ocr/${id}`,
            uploadData
          );
          setConsumerType("");
          console.log({redirectValue});
          
          if (redirectValue === "true") {
            navigate("/power-consumption?filter=true");
          }else if(redirectValue === "analytics") {
            navigate("/analytics?filter=true");
          } 
          else {
            navigate("/compare-ocr-bills?updateFilter=true");
          }
        } else {
          console.log("Error in Process Raw Data");
        }
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const parseDate = (dateString) => {
  
    if (!dateString || typeof(dateString) !== 'string') return null; // Add a check for undefined or null dateString
    let dateParts;
    if (dateString.includes("-")) {
      dateParts = dateString.split("-");
      if (dateParts.length !== 3) return null; // Ensure correct number of date components
      const [day, month, year] = dateParts;
      return new Date(
        `${year.length === 2 ? "20" + year : year}-${month}-${day}`
      );
    } else if (dateString.includes("/")) {
      dateParts = dateString.split("/");
      if (dateParts.length !== 3) return null; // Ensure correct number of date components
      const [day, month, year] = dateParts;
      return new Date(
        `${year.length === 2 ? "20" + year : year}-${month}-${day}`
      );
    } else {
      return null; // Invalid date format
    }
  };

  const formatDate = (date) => {
    // console.log(date);
    if (!date) return ""; // Return an empty string if date is null or undefined

    // Check if the date is already a Date object
    if (date instanceof Date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    } else if (typeof date === "string") {
      // Parse the date string assuming it is in the format "dd/MM/yyyy"
      const [day, month, year] = date.split("/");
      if (day && month && year) {
        const parsedDate = new Date(`${year}-${month}-${day}`);
        if (!isNaN(parsedDate.getTime())) {
          return formatDate(parsedDate); // Recursively call formatDate with the parsed Date object
        }
      }
    }
    return ""; // Return an empty string if date is not in a valid format
  };

  const getSelectedText = (text, index) => {
    setSelectedText(text);
    setTimeout(() => {
      if (inputRefs.current[index] && inputRefs.current[index].current) {
        inputRefs.current[index].current.focus();
      }
    }, 0);
  };

  const InputBox = ({
    type,
    label,
    value,
    onChange,
    required = false,
    error,
    index,
  }) => {
    return (
      <div className="flex flex-row items-center w-[100%]">
        <div className="w-[50%]">
          <label className="text-sm font-medium mb-1 ">
            {label}
            {required ? <span className="text-[red] ml-[4px]">*</span> : ""}
          </label>
        </div>
        <div className="flex flex-col w-[50%]">
          <div>
            <input
              // ref={inputRef}
              ref={inputRefs.current[index]}
              type={type}
              value={value}
              onChange={onChange}
              required={required}
              onFocus={() => getSelectedText(label, index)}
              className={`border p-3 outline-none text-sm h-[50px] rounded-2xl w-[100%] ${
                error ? "border-red-500" : "border-gray-300"
              }`}
            />
          </div>
          <div>{error && <p className="text-red-500 text-sm">{error}</p>}</div>
        </div>
      </div>
    );
  };

  const handleNavigation = () => {
    if (redirectValue === "true") {
      navigate("/power-consumption?filter=true");
    }
    else if(redirectValue === "analytics") {
      navigate("/analytics?filter=true");
    } 
    else {
      navigate("/compare-ocr-bills?updateFilter=true");
    }
  };

  return (
    <div className="max-w-[1920px] px-[20px] sm:px-[50px] relative m-auto mt-[80px] sm:mt-[100px] pb-20">
      <div className="">
        <div className="flex justify-end">
          <BackButton
            link={redirectValue === "true" ? "/power-consumption?filter=true" :redirectValue === "analytics" ? "/analytics?filter=true" : "/compare-ocr-bills?updateFilter=true"}
          />
        </div>

        <div className="">
          <div className="text-[22px] pb-5 text-center font-bold leading-[42px]">
            Update OCR Bills Data
          </div>

          {loading ? (
            <div className="mt-14">
              <LoaderComponent />
            </div>
          ) : (
            <div className="flex flex-col-reverse lg:flex-row">
              <div className="mr-[20px] w-[100%] lg:w-[70%] mt-10 lg:mt-0 h-[86vh] sm:h-[100%] overflow-hidden">
                <PdfHighlighter pdfUrl={billView} selectedText={selectedText} />
              </div>

              <div className="rounded-lg  bg-[#E2F5EC] flex flex-col shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto w-[100%] lg:w-[30%]">
                {!loading && (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="flex flex-col space-y-4 p-4 max-h-[710px] overflow-y-auto -z-0">
                      {fieldNames?.map((fieldName, index) => {
                        // console.log(fieldName,'fieldName');
                        const isRequired = requiredFields.includes(fieldName);
                        const isDateInput = dateInputs.includes(fieldName);
                        return (
                          <div
                            className={`${
                              index === 0 || index === fieldNames.length - 1
                                ? "block"
                                : "block"
                            } items-center`}
                            key={index}
                          >
                            {isDateInput ? (
                              <div className="flex flex-row items-center w-[100%]">
                                <div className="w-[50%]">
                                  <label className="text-sm font-medium mb-1 ">
                                    {field[index] !== null && field[index]}
                                  </label>
                                </div>
                                <div className="validate-ocr relative w-[50%]">
                                  <DatePicker
                                    onInputClick={() =>
                                      getSelectedText(field[index], index)
                                    }
                                    key={ocrData.values[index]}
                                    selected={
                                      ocrData?.values[index]
                                        ? parseDate(ocrData?.values[index])
                                        : ""
                                    }
                                    onChange={(date) =>
                                      handleInputChange(formatDate(date), index)
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    className="border p-3 rounded-2xl text-sm cursor-pointer w-[100%]"
                                  />
                                  <div className="absolute right-0 top-2 mt-[10px] mr-[10px]">
                                    <div>
                                      <img
                                        src="/calenderIcon.svg"
                                        className="h-[18px] w-[18px]"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                {errors[fieldName] && (
                                  <p className="text-red-500 text-sm">
                                    {errors[fieldName]}
                                  </p>
                                )}
                              </div>
                            ) : (
                              field[index] !== null && (
                                <InputBox
                                  key={index}
                                  index={index}
                                  id={fieldName}
                                  type="text"
                                  label={field[index]}
                                  value={ocrData.values[index] ?? ""}
                                  required={isRequired}
                                  error={errors[fieldName]}
                                  onChange={(e) =>
                                    handleInputChange(e.target.value, index)
                                  }
                                />
                              )
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex flex-row mx-auto w-fit h-[100px] z-40">
                      <div className="w-fit mr-5 pb-5">
                        <button
                          type="submit"
                          className="mt-6 text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] text-white bg-[#067C4E]"
                        >
                          Submit Changes
                        </button>
                      </div>
                      <div className="w-fit  pb-5">
                        <button
                          onClick={handleNavigation}
                          className="mt-6 text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] text-white bg-[#067C4E]"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}
          <br />
        </div>
      </div>
    </div>
  );
};

export default ValidateBillsOcrData;
