import axios from "axios";
// const localStr = localStorage.getItem('user')
// console.log(localStr);
// const user = JSON.parse(localStorage.getItem('user'));
// const token = user?.result?.token
// console.log(token);
export const postData = async (url, data) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(url, data);
    return { data: response.data };
  } catch (error) {
    console.error(`Error in postData: ${error}`);
    return { error };
  }
};

export const updateData = async (url, data) => {
  // console.log(url);
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.put(url, data);
    return { data: response.data };
  } catch (error) {
    console.error(`Error in updateData: ${error}`);
    return { error };
  }
};

export const postList = async (url, data) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(url, {
      buyerId: localStorage.getItem("userId"),
      data,
    });
    return response.data;
  } catch (error) {
    console.error(`Error in getting list: ${error}`);
    return { error };
  }
};

export const getData = async (url) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    // console.log(user);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error in updateData: ${error}`);
    return { error };
  }
};

export const deleteData = async (url) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(`Error in updateData: ${error}`);
    return { error };
  }
};

export const postImageData = async (url, data, disComData) => {
  try {
    // console.log(data[0]);
    const formData = new FormData();
    data.forEach((file, index) => {
      formData.append(`files`, file);
    });
    // formData.append('files', data[0]);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    // console.log(disComData);
    if (disComData) {
      formData.append(`stateCode`, disComData.stateCode);
      formData.append(`discomCode`, disComData.discomCode);
      formData.append(`consumerType`, disComData.consumerType);
    }
    formData.append("buyerId", localStorage.getItem("userId"));
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const response = await axios.post(url, formData);
    return response;
  } catch (error) {
    console.error(`Error in postData: ${error}`);
    return { error };
  }
};

export const postOcrList = async (url, data) => {
  try {
    let payload = {};
    if (data) {
      console.log(data);
      payload = {
        jobId: data.jobId,
        status: data.filter && data.filter?.status,
        pageSize: data.limit,
        page: data.page,
        consumerName: data.filter && data.filter?.consumerName,
        consumerNo: data.filter && data.filter?.consumerNo,
        startReadingDate: data.filter && data.filter?.startReadingDate,
        endReadingDate: data.filter && data.filter?.endReadingDate,
        sortBy:
          data?.sortBy === "billMonthOfLastDay"
            ? data?.sortBy
            : `data.${data?.sortBy}.value`,
        sortOrder: data?.sortOrder,
        stateCode: data?.filter?.stateCode,
        discomCode: data?.filter?.discomCode,
      };
    }
    // console.log(payload);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(url, {
      buyerId: localStorage.getItem("userId"),
      ...payload,
    });
    return response.data;
  } catch (error) {
    console.error(`Error in getting list: ${error}`);
    return { error };
  }
};

export const fetchStateList = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/state/list`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.error("Error :", error);
    return error?.response?.data;
  }
};

export const fetchDiscomList = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/disComCode/list`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.error("Error :", error);
    return error?.response?.data;
  }
};
