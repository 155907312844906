import React, { useState, useEffect } from "react";
import { faClose, faCompress } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import LegendComponent from "../../components/chart/LegendComponent";
import CustomToolTipICon from "../../components/tooltips/CustomToolTipICon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const LIneChartEnergyTOD = ({ data, options, full, setFull }) => {
  useEffect(() => {
       console.log({ updatedData: { data, options } })
  }, [data, options]);

  if (full === true) {
    return (
      <div className="fixed left-0 top-0 bg-white w-full z-50 flex flex-col justify-center h-screen  items-center">
    
<div
          className="left-0 sm:left-[94%] z-40 sm:right-0 top-1 absolute px-5 sm:px-10  pt-4 sm:pt-2 cursor-pointer"
          onClick={() => setFull(false)}
        >
          <CustomToolTipICon
            icon={faClose}
            size="xl"
            color={"black"}
            text={"close"}
          />
        </div>
        <div className="absolute hidden  top-2 w-full md:flex justify-center">
          <LegendComponent data={data} columns={"grid-cols-4"} />
        </div>
        <div
          // style={{
          //   position: "relative",
          //   margin: "auto",
          //   width: "90vw",
          //   height: "90vh",
          // }}
          className="line-graph"
        >
          <div className=" w-full flex md:hidden justify-center">
            <LegendComponent data={data} columns={"grid-cols-4"} />
          </div>

          <Bar options={options} data={data} />
        </div>{" "}
      </div>
    );
  } else {
    return (
      <div>
        <Bar options={options} data={data} />
      </div>
    );
  }
};

export default LIneChartEnergyTOD;
