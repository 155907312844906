import { graphData } from "../../files/sampleData/graphdata";
import axios from "axios";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { GraphUtils } from "../../utils/graphUtils";
import { mutateTreeWithIndex } from "@minoru/react-dnd-treeview";

const constantUtils = new ConstantUtils();
const graphUtils = new GraphUtils();

export class BillConsumptionService {
  buyerId = localStorage.getItem("userId");
  monthNameArray = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];

  Months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  normalMonthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  getAnnualBillConsumption() {
    try {
      const data = Object.values(
        graphData[0].organisations.organisation1.annualEnergyPowerConsumption
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  async getAllOrganisations() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        {
          buyerId: localStorage.getItem("userId"),
        }
      );
      return response.data.data.results;
    } catch (err) {
      //      console.log(err);
    }
  }

  async getMetersListWithUserNameAndOrganisationName(unit) {
    // console.log({selectedUnitData:unit})
    try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_API_URL}/organisation/list`,
      //     {
      //       buyerId: localStorage.getItem("userId"),
      //     }
      //   );

      //   const organisationListData = response.data;

      //   const organisations = organisationListData.data.results.map(
      //     (item) => item._id
      //   );

      //   //    //   console.log({ MetersListWithUserNameAndOrganisationName: organisations });

      const metersList = await axios.post(
        `${process.env.REACT_APP_API_URL}/meter/list`,
        {
          unitId: unit,
        }
      );

      const meterListData = metersList.data.data.results.map((meter) => meter);
      console.log({ MetersListWithUnitName: meterListData });
      return meterListData;
    } catch (error) {}
  }
  getAnnualMeterConsumption(factory, unit, meter) {
    try {
      //    //   console.log({ factory, unit, meter });
      if (factory !== "" && unit !== "" && meter !== "") {
        const data = Object.values(
          graphData[0].organisations.organisation1.factories[factory].units[
            unit
          ].meters[meter].annualPowerConsumption
        );
        return data;
      }
    } catch (error) {
      //      console.log(error);
    }
  }

  YearMonthArray = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  YearMonthArrayNormal = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  async getMonthlyBillDistrubution(year, month, meter) {
    //    // console.log({meter})
    try {
      const monthToLowweCase = month.toLocaleLowerCase();
      const monthIndex = this.normalMonthArray.indexOf(
        this.capitalizeFirstLetter(monthToLowweCase)
      );
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-demand`,
        {
          buyerId: localStorage.getItem("userId"),
          year,
          monthNumber: monthIndex + 1,
          meterId: meter,
        }
      );
      //    //   console.log({ MonthlyBillDistrubution: response.data.data[0] });
      return response.data.data[0];
    } catch (error) {
      //      console.log({ error });
    }
  }

  async getBilledDemand(year, unit) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-demand`,
        {
          buyerId: localStorage.getItem("userId"),
          year: year,
          unitId: unit,
        }
      );

      if (response.data && response.data.data) {
        const billedDemandArray = [];
        const rsperkwhArray = [];

        response.data.data.forEach((item) => {
          const monthIndex = this.monthNameArray.indexOf(item.month);

          billedDemandArray[monthIndex] = item.data?.billedDemand?.value || 0;
          rsperkwhArray[monthIndex] = item.data?.rsPerKWH?.value || 0;

          //        //   console.log({ BilledDemand});
        });
        console.log({ rsperkwhArray });
        return { year, unit, data: billedDemandArray, rsperkwh: rsperkwhArray };
      }
    } catch (error) {
      //      console.log(error);
    }
  }

  async getMonthlyBilledDemand(year, month, meter) {
    try {
      //    //   console.log({ "monthly-consumption-demand-month": month, meter });
      const lowercaseMonth = month.toLocaleLowerCase();
      const monthIndex = this.normalMonthArray.indexOf(
        this.capitalizeFirstLetter(lowercaseMonth)
      );
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-demand`,
        {
          buyerId: localStorage.getItem("userId"),
          year: year,
          monthNumber: monthIndex + 1,
          meterId: meter,
        }
      );

      if (response.data && response.data.data) {
        const billedDemand = response?.data?.data[0].data?.billedDemand?.value;
        const contractDemand =
          response?.data?.data[0].data?.contractDemand?.value;
        const percentageOfContract =
          response.data.data[0].data?.percentageOfcontractDemand?.value;
        const totalCost =
          response?.data?.data[0]?.data?.totalCostRsPerUnit?.value;
        const energyVariableCost =
          response?.data?.data[0]?.data?.energyVariableCost?.value;
        const RsPerKwh = response.data.data[0].data?.rsPerKWH?.value;

        return {
          year,
          month,
          billedDemand,
          contractDemand,
          percentageOfContract,
          totalCost,
          energyVariableCost,
          RsPerKwh,
        };
      }
    } catch (error) {
      //      console.log(error);
    }
  }

  async getMultipleUnitsEnergyData(unitids, year) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/yearly-comparison-chart`,
        {
          buyerId: localStorage.getItem("userId"),
          unitId: unitids,
          billYear: year,
        }
      );
      let multipleUnitsEnergyData = [];
      if (response.data) {
        multipleUnitsEnergyData = response.data.data.data.years[0];
      }

      if (multipleUnitsEnergyData ) {
        const data = { labels: [], energy: [], costs: [] };
        multipleUnitsEnergyData.data.forEach((unitData, index) => {
        
            data.labels.push(`${this.monthNameArray[index]}-${year}`);
            data.energy.push(unitData["energy"] || 0);
            data.costs.push(unitData["cost"] || 0);
        
        });
    
        return data;
      }

    } catch (error) {
           console.log(error);
    }
  }

  async getMonthlyConsumptionTodSlotWithYearAndOrg(
    year,
    meter,
    slotnames,
    slotColors,
    slotConfigData
  ) {
    try {
      //  console.log({ slotnames, year, meter,slotColors });
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/simulation`,
        {
          buyerId: localStorage.getItem("userId"),
          year,
          type: "month",
          meterId: meter,
        }
      );

      if (data.data.length === 0) {
        return {
          [meter]: {
            [year]: {
              energyMonthLyData: [],
              costMonthLyData: [],
            },
          },
        };
      }

      let yearDataArray = [];

      //    //   console.log({ yearlydata: data.data });
      this.Months.forEach((month, index) => {
        //        // console.log({ month });
        let monthData = data.data.find((item) => item.month === month);
        //        // console.log({ monthName: monthData });
        if (monthData) {
          yearDataArray[index] = monthData.slots;
        }
        //        // console.log({ yearDataArray });
      });

      let slotsEnergyData = [];
      let slotsCostData = [];
      let slotsData = {};
      yearDataArray.forEach((data, index) => {
        const slotKeys = Object.keys(data);
        const slots = [...new Set(slotKeys)];

        slots.forEach((key) => {
          //        //   console.log({ index });
          slotnames.forEach((slotKey) => {
            if (key === slotKey) {
              const arrayname1 = slotKey + "EnergyDataArray";
              if (!slotsData[arrayname1]) {
                slotsData[arrayname1] = [];
              }
              slotsData[arrayname1][index] = Math.floor(data[key].units);
              const arrayname2 = slotKey + "CostDataArray";
              if (!slotsData[arrayname2]) {
                slotsData[arrayname2] = [];
              }
              slotsData[arrayname2][index] = Math.floor(data[key].cost);
            }
          });
        });
      });

      //  console.log({ slotsData });

      slotnames.forEach((slotKey) => {
        const arrayname1 = slotKey + "EnergyDataArray";
        if (slotsData[arrayname1]) {
          slotsEnergyData.push({
            label: graphUtils.fomatSlotName(slotKey, slotConfigData),
            backgroundColor: slotColors[slotKey],
            borderWidth: 1,
            type: "bar",
            data: slotsData[arrayname1],
            yAxisID: "y",
            order: 2,
          });
        }

        const arrayname2 = slotKey + "CostDataArray";
        if (slotsData[arrayname2]) {
          slotsCostData.push({
            label: graphUtils.fomatSlotName(slotKey, slotConfigData),
            backgroundColor: slotColors[slotKey],
            data: slotsData[arrayname2],
            yAxisID: "y",
            type: "bar",
            order: 2,
          });
        }
      });

      //    //   console.log({ slotsEnergyData });
      //    //   console.log({ slotsCostData });

      return {
        [meter]: {
          [year]: {
            energyMonthLyData: slotsEnergyData,
            costMonthLyData: slotsCostData,
          },
        },
      };
    } catch (error) {
      //      console.log({ error });
    }
  }

  async getMonthlyConsumptionTodSlot(
    year,
    slotnames,
    unit,
    slotColors,
    allSlotTimeData
  ) {
    try {
      // console.log({slotColors})
      // console.log({allSlotTimeData})
      //    //   console.log({ year, slotnames, unit });
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/todSlot`,
        {
          buyerId: localStorage.getItem("userId"),
          year,
          type: "month",
          unitId: [unit],
        }
      );

      //   let { data } = await axios.post(
      //     `https://28105c33-0f8c-49fa-8700-7aab37d8903b.mock.pstmn.io/get`,
      //     {
      //       buyerId: localStorage.getItem("userId"),
      //       year,
      //       type: "month",
      //     }
      //   );

      if (data.data.length === 0 && slotnames.length === 0) {
        //        // console.log("slotdata not found")
        return {
          [year]: {
            [unit]: {
              energyMonthLyData: [],
              costMonthLyData: [],
            },
          },
        };
      }

      let yearDataArray = [];
      //    //   console.log({ yearlydata: data.data });
      this.Months.forEach((month, index) => {
        //        // console.log({ month });
        let monthData = data.data.find((item) => item.month === month);
        //        // console.log({ monthName: monthData });
        if (monthData) {
          yearDataArray[index] = monthData.slots;
        }
        console.log({ yearDataArray });
      });

      const hasRTC = yearDataArray.some((item) => item.hasOwnProperty("RTC"));
      if (yearDataArray && yearDataArray.length > 0 && hasRTC) {
        const configData = JSON.parse(localStorage.getItem("config"));

        const RTCConfig = configData["slotConfig"]["COMMERCIAL"];
        console.log({ RTCConfig });
        allSlotTimeData = RTCConfig;
        slotnames = [];
        slotColors = [];
        slotnames = Object.keys(RTCConfig["slotName"]);

        slotColors = RTCConfig["slotColor"];
      }
      let slotsEnergyData = [];

      let slotsCostData = [];
      let slotsData = {};
      yearDataArray.forEach((data, index) => {
        const slotKeys = Object.keys(data);
        const slots = [...new Set(slotKeys)];
        // console.log({slots})

        slots.forEach((key) => {
          slotnames.forEach((slotKey) => {
            if (key === slotKey) {
              const arrayname1 = slotKey + "EnergyDataArray";
              if (!slotsData[arrayname1]) {
                slotsData[arrayname1] = [];
              }
              slotsData[arrayname1][index] = Math.floor(data[key].units);
              const arrayname2 = slotKey + "CostDataArray";
              if (!slotsData[arrayname2]) {
                slotsData[arrayname2] = [];
              }
              slotsData[arrayname2][index] = Math.floor(data[key].cost);
            }
          });
        });
      });

      //    //   console.log({ slotsData });

      slotnames.forEach((slotKey) => {
        const arrayname1 = slotKey + "EnergyDataArray";
        if (slotsData[arrayname1]) {
          slotsEnergyData.push({
            label: graphUtils.fomatSlotName(slotKey, allSlotTimeData),
            backgroundColor: slotColors[slotKey],
            data: slotsData[arrayname1],
            yAxisID: "y",
            order: 2,
          });
        }

        const arrayname2 = slotKey + "CostDataArray";
        if (slotsData[arrayname2]) {
          slotsCostData.push({
            label: graphUtils.fomatSlotName(slotKey, allSlotTimeData),
            backgroundColor: slotColors[slotKey],
            data: slotsData[arrayname2],
            yAxisID: "y",
            order: 2,
          });
        }
      });

      //    //   console.log({ slotsEnergyData });
      //    //   console.log({ slotsCostData });
      return {
        [year]: {
          [unit]: {
            energyMonthLyData: slotsEnergyData,
            costMonthLyData: slotsCostData,
          },
        },
      };
    } catch (error) {
      //      console.log({ error });
    }
  }

  getFiscalYearLabel(year) {
    const nextYear = year + 1;
    return `FY ${year}-${nextYear.toString().slice(-2)}`;
  }

  async getMonthlyCostConsumptionTodSlot(year) {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-tod-slot-v2`,
        {
          buyerId: localStorage.getItem("userId"),
          year,
        }
      );

      let yearDataArray = [];

      this.monthNameArray.forEach((month) => {
        //        // console.log({ MOnthData: month });
        let monthData = data.data.find(
          (item) => item.month === month.toLocaleLowerCase()
        );
        if (monthData) {
          yearDataArray.push(monthData.slots[0]);
        }
      });

      //    //   console.log({ yearDataArray });

      let slotsData = [];
      const B1DataArray = [];
      const B2DataArray = [];
      const G1DataArray = [];
      const G2DataArray = [];
      const YDataArray = [];
      const RDataArray = [];

      yearDataArray.forEach((data) => {
        const slotKeys = Object.keys(data);
        const slots = [...new Set(slotKeys)];

        slots.forEach((key) => {
          if (key === "B1") {
            B1DataArray.push(Math.floor(data[key].cost));
          } else if (key === "G1") {
            B2DataArray.push(Math.floor(data[key].cost));
          } else if (key === "Y") {
            G1DataArray.push(Math.floor(data[key].cost));
          } else if (key === "G2") {
            G2DataArray.push(Math.floor(data[key].cost));
          } else if (key === "R") {
            YDataArray.push(Math.floor(data[key].cost));
          } else if (key === "B2") {
            RDataArray.push(Math.floor(data[key].cost));
          }
        });
      });
      slotsData.push(
        B1DataArray,
        B2DataArray,
        G1DataArray,
        G2DataArray,
        YDataArray,
        RDataArray
      );
      console.log({ slotsData });
      return { year, data: slotsData };
    } catch (error) {
      //      console.log({ error });
    }
  }
  async GetGraphdata(meterList) {
    try {
      //getorganisation for particular state
      console.log({ meterList });
      const orgData = await this.getOrganisationByState();
      console.log({ orgData });

      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/yearly-comparison-chart`,
        {
          buyerId: localStorage.getItem("userId"),
          meterId: meterList,
        }
      );

      let result = {
        years: [],
        months: [],
        energyData: [],
        costData: [],
        solarCostData: [],
        solarEnergyData: [],
        netEnergyData: [],
        netCostData: [],
      };
      //      //   console.log({ response });
      if (data && data.data.data.years) {
        data.data.data.years.forEach((year) => {
          let monthdataArray = [];
          let monthCostArray = [];
          let solarEnergyArray = [];
          let solarCostArray = [];
          let netEnergyArray = [];
          let netCostArray = [];
          year.data.forEach((monthData) => {
            let index = this.YearMonthArray.indexOf(monthData.month);
            monthdataArray[index] = monthData.energy || 0;
            monthCostArray[index] = monthData.cost || 0;
            solarEnergyArray[index] = monthData.solarEnergy || 0;
            solarCostArray[index] = monthData.solarCost || 0;
            netEnergyArray[index] = monthData.netEnergy || 0;
            netCostArray[index] = monthData.netCost || 0;
          });

          const fiscalYear = this.getFiscalYearLabel(year.year);
          result.years.push(fiscalYear);

          result.energyData.push(monthdataArray);
          result.costData.push(monthCostArray);
          result.solarEnergyData.push(solarEnergyArray);
          result.solarCostData.push(solarCostArray);
          result.netEnergyData.push(netEnergyArray);
          result.netCostData.push(netCostArray);
        });
        result.months = this.monthNameArray;
        //        // console.log({ result });
      }
      //    //   console.log({ result });

      return result;
    } catch (error) {
      //      console.log(error);
    }
  }

  async GetGraphCostdata() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/yearly-comparison-chart`,
        {
          buyerId: localStorage.getItem("userId"),
        }
      );

      let result = { years: [], months: [], values: [] };
      //    //   console.log({ response });
      if (response.data && response.data.data.data.years) {
        response.data.data.data.years.forEach((year) => {
          let monthdataArray = [];

          year.data.forEach((monthData) => {
            let index = this.YearMonthArray.indexOf(monthData.month);
            //            // console.log({ index });
            monthdataArray[index] = monthData.cost || 0;
          });

          result.years.push(year.year);

          result.values.push(monthdataArray);
        });
        result.months = this.monthNameArray;
        //        // console.log({ result });
      }
      //    //   console.log({ result });

      return result;
    } catch (error) {
      //      console.log(error);
    }
  }

  async getOrganisationByState(stateCode) {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/unit/list`,
        {
          buyerId: localStorage.getItem("userId"),
        }
      );

      const unitsData = data?.data?.results;

      const filteredUnits = unitsData.filter((unit) => {
        return unit.stateCode === stateCode;
      });

      const filteredUnitsOrgIds = filteredUnits.map((unit) => {
        return unit.organisationId._id;
      });
      console.log({ filteredUnitsOrgIds });
      return filteredUnitsOrgIds;
    } catch (error) {
      console.log({ error });
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async getSpecificMonthTodData(
    year,
    month,
    meter,
    slotnames,
    slotColors,
    slotConfigData
  ) {

    console.log({withingettodData:"sada",slotConfigData});

    try {
      const monthIndex = this.normalMonthArray.indexOf(
        this.capitalizeFirstLetter(month)
      );
      //   const { data } = await axios.post(
      //     `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-tod-slot-v2`,
      //     {
      //       buyerId: localStorage.getItem("userId"),
      //       year: year,
      //       monthNumber: monthIndex + 1,
      //       meterId: meter,
      //     }
      //   );

      //    //   console.log({slotColors});
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/visualization/monthly-consumption-tod-slot-v2`,
        {
          buyerId: localStorage.getItem("userId"),
          year: year,
          monthNumber: monthIndex + 1,
          meterId: meter,
        }
      );
      console.log({data});
      
      if (data.data.length === 0) {
        return { year, month, meter, data: [] };
      }
      if (data && data.data) {
        const slotData = [data.data[0].slots];
        const initialObject = Object.keys(slotData[0]);
        if (slotData && slotData.length > 0 && initialObject.includes("RTC")) {
          const configData = JSON.parse(localStorage.getItem("config"));

          const RTCConfig = configData["slotConfig"]["COMMERCIAL"];
          console.log({ RTCConfig });
          slotConfigData = RTCConfig;
          slotnames = [];
          slotColors = [];
          slotnames = Object.keys(RTCConfig["slotName"]);
          slotColors = RTCConfig["slotColor"];
        }
        const slotUnitValue = [];
        const slotCostValue = [];
        const slotDemand = [];
        const slotLF = [];
        const labels = [];
        console.log({ slotData });
        slotData.forEach((Slot) => {
          if (Object.keys(Slot).length > 0) {
            Object.keys(Slot).forEach((key) => {
              if (!labels.includes(key)) {
                labels.push(key);
              }
            });
          }
          slotnames.forEach((key, index) => {
            if (Slot[key]?.units !== undefined) {
              slotUnitValue[index] = Math.floor(Slot[key].units);
            }
            if (Slot[key]?.cost !== undefined) {
              slotCostValue[index] = Math.floor(Slot[key].cost);
            }
            if (Slot[key]?.demand !== undefined) {
              slotDemand.push(Math.floor(Slot[key].demand));
            }
            if (Slot[key]?.loadFactor !== undefined) {
              slotLF.push(Slot[key].loadFactor);
            }
          });
        });

        const energyGraphData = [
          {
            label: "slots",
            backgroundColor: Object.values(slotColors),
            data: slotUnitValue,
            yAxisID: "y",
            type: "bar",
            order: 2,
          },
        ];

        const costGraphData = [
          {
            label: "slots",
            backgroundColor: Object.values(slotColors),

            data: slotCostValue,
            yAxisID: "y",
            type: "bar",
            order: 2,
          },
        ];
        console.log({ labels });

        const arra1 = [];
        labels.forEach((label1) => {
          const updatedLabels = graphUtils.fomatSlotNameWithTimeLabels(
            label1,
            [],
            slotConfigData
          );

          console.log({ updatedLabels });
          arra1.push(updatedLabels);
        });

        console.log({ arra1 });

               console.log({
          result: {
            year,
            month,
            meter,
            data: energyGraphData,
            cost: costGraphData,
            demand: slotDemand,
            lf: slotLF,
          },
        });
        return {
          year,
          month,
          meter,
          data: energyGraphData,
          cost: costGraphData,
          demand: slotDemand,
          lf: slotLF,
          updatedLabels: arra1,
        };
      }
    } catch (error) {
      //      console.log({ error });
    }
  }

  getFormattedDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() is zero-based
    let day = date.getDate();

    // Pad the month and day with leading zeros if necessary
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  async uploadFileData(data, meterId) {
    try {
      //    //   console.log({ data, meterId });
      let headers = data[0].map((date) =>
        date ? this.getFormattedDate(date) : ""
      );
      headers = headers.filter((item) => item !== ""); // Remove empty strings
      //    //   console.log({ headers });
      let subHeaders = data[1].slice(2, data.length);
      //    //   console.log({ subHeaders });
      subHeaders = subHeaders.map((date, index) => (date ? index + 1 : ""));
      subHeaders = subHeaders.filter((item) => item !== "");
      subHeaders.unshift("Hour");
      subHeaders.unshift("Time Block");
      //    //   console.log({ subHeaders });
      let dataBeforeProcess = data.slice(2, data.length);
      let finalData = [];
      let day = "";
      finalData.push(headers);
      finalData.push(subHeaders);
      dataBeforeProcess.forEach((todData) => {
        day = todData[0] ? todData[0] : "";
        finalData.push([day, ...todData.slice(1, todData.length)]);
      });
      //    //   console.log({ finalData });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customizeTodSlot/processData`,
        {
          data: finalData,
          meterId,
        }
      );
      //    //   console.log({ UploadFileDataResponse: response.data });

      return response.data;
    } catch (error) {
      //      console.log({ error });
    }
  }
  async getSpecificDayTodData(
    year,
    month,
    meter,
    slotnames,
    slotColors,
    slotConfigData
  ) {
    //    // console.log({slotColors})
    try {
      const monthIndex = this.normalMonthArray.indexOf(
        this.capitalizeFirstLetter(month)
      );
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/customVisualization/daywise-consumption-tod-slot`,
        {
          buyerId: localStorage.getItem("userId"),
          year: year,
          monthNumber: monthIndex + 1,
          meterId: meter,
        }
      );

      const array_6x31 = Array.from({ length: 6 }, () => Array(31).fill(0));
      const SlotsData = slotnames;

      data.data.forEach((dayData, index) => {
        SlotsData.forEach((key, idx) => {
          array_6x31[idx][index] = Math.floor(dayData.slots[0][key].units);
        });
      });

      const finalData = [];
      SlotsData.forEach((slot, index) => {
        finalData.push({
          label: graphUtils.fomatSlotName(slot, slotConfigData),
          data: array_6x31[index],
          backgroundColor: slotColors[slot],
          borderWidth: 1,
        });
      });
      //      console.log({ finalData });

      return { finalData, year, meter, month };
    } catch (error) {
      //      console.log({ error });
    }
  }

  getannualEnergyConsumptionLabels() {
    try {
      const data = Object.keys(
        graphData[0].organisations.organisation1.annualEnergyPowerConsumption
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  getMonthlyEnergyBillConsumptionData(selectedEnergyYear) {
    try {
      const data = Object.values(
        graphData[0].organisations.organisation1.monthlyEnergyPowerConsumption[
          selectedEnergyYear
        ]
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  getMonthlyEnergyBillConsumptionLabels(selectedEnergyYear) {
    try {
      const data = Object.keys(
        graphData[0].organisations.organisation1.monthlyEnergyPowerConsumption[
          selectedEnergyYear
        ]
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  getAnnualCostConsumptionData() {
    try {
      const data = Object.values(
        graphData[0].organisations.organisation1.annualCostPowerConsumption
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  getAnnualCostConsumptionLabels() {
    try {
      const data = Object.keys(
        graphData[0].organisations.organisation1.annualCostPowerConsumption
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }
  getMonthLyCostConsumptionData(selectedCostYear) {
    try {
      if (selectedCostYear !== "" || selectedCostYear !== undefined) {
        const data = Object.values(
          graphData[0].organisations.organisation1.monthlyCostPowerConsumption[
            selectedCostYear
          ]
        );
        return data;
      }
    } catch (error) {
      //      console.log(error);
    }
  }

  getMonthLyConstConsumptionLabels(selectedCostYear) {
    try {
      if (selectedCostYear !== "" || selectedCostYear !== undefined) {
        const data = Object.keys(
          graphData[0].organisations.organisation1.monthlyCostPowerConsumption[
            selectedCostYear
          ]
        );
        return data;
      }
    } catch (error) {
      //      console.log(error);
    }
  }

  //   getAllOrganisations() {
  //     try {
  //       const data = Object.keys(graphData[0].organisations);
  //       return data;
  //     } catch (error) {
  //  //       console.log(error);
  //     }
  //   }

  async getOrganisationtree(organisationId) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user?.result?.token;
      //      console.log({ organisationId });
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/organisation/tree/${organisationId}`
      );
      //      console.log({ response });
      return response.data.data;
    } catch (error) {
      //      console.log(error);
    }
  }

  async getAllFactories(organisationId) {
    try {
      //      console.log({ organisationId });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/factory/list`,
        {
          buyerId: localStorage.getItem("userId"),
          organisationId,
        }
      );
      //      console.log({ response });
      return response.data.data.results;
    } catch (error) {
      //      console.log(error);
    }
  }

  async getAllUnitsByUserId() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/unit/list`,
        {
          buyerId: localStorage.getItem("userId"),
        }
      );
      //      console.log({ response });
      return response.data.data.results;
    } catch (error) {
      //      console.log(error);
    }
  }
  async getAllUnits(organisationId, factoryId) {
    try {
      //      console.log({ organisationId, factoryId });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/unit/list`,
        {
          buyerId: localStorage.getItem("userId"),
          organisationId,
          factoryId,
        }
      );
      //      console.log({ response });
      return response.data.data.results;
    } catch (error) {
      //      console.log(error);
    }
  }
  //==========================================================
  //                      energy tod
  //===========================================================

  async getAllEnergyMeters(organisationId, factoryId, unitId) {
    try {
      //      console.log({ organisationId });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/meter/list`,
        {
          buyerId: localStorage.getItem("userId"),
          organisationId,
          factoryId,
          unitId,
        }
      );
      //      console.log({ response });
      return response.data.data.results;
    } catch (error) {
      //      console.log(error);
    }
  }

  getEnergyTodYearsList(selectedMeter) {
    try {
      const data = Object.keys(
        graphData[0].organisations.organisation1.factories.factory1.units.unit1
          .meters[selectedMeter].TodData
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }
  getTodEnergyMonthsList(selectedMeter, selectedYear) {
    try {
      const data = Object.keys(
        graphData[0].organisations.organisation1.factories.factory1.units.unit1
          .meters[selectedMeter].TodData[selectedYear]
      );
      return data;
    } catch (error) {
      //      console.log(error);
    }
  }

  getEnergyMeterdata = (selectedMeter, selectedYear, selecteMonth) => {
    try {
      if (
        (selectedMeter !== "" || selectedMeter !== undefined) &&
        (selectedYear !== "" || selectedYear !== undefined) &&
        (selecteMonth !== "" || selecteMonth !== undefined)
      ) {
        const data =
          graphData[0].organisations.organisation1.factories.factory1.units
            .unit1.meters[selectedMeter].TodData[selectedYear][selecteMonth];

        return data;
      }
    } catch (error) {
      //      console.log(error);
    }
  };
}
