import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import ASelect from "../../components/select/ASelect";
import { getPowerConsumptionList } from "../../services/apis/powerConsumption";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchDiscomList,
  fetchStateList,
  getData,
  postData,
  postList,
  updateData,
} from "../../api/apiDataExchange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCloudDownload,
} from "@fortawesome/free-solid-svg-icons";

import Pagination from "../../components/pagination/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./PowerConsumption.css";
import { useAuth } from "../../hooks/useAuth";
import GoTODashboard from "../../components/button/GoTODashboard";
import moment from "moment/moment";
import FilterSelect from "../../components/select/FilterSelect";
import { MultiSelect } from "react-multi-select-component";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import CsvFieldSelector from "../../components/popup/CsvFieldSelector";
import { ToastContainer } from "react-toastify";

const PowerConsumption = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [consumerNos, setConsumerNos] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [org, setOrg] = useState("");
  const [factory, setfactory] = useState("");
  const [unitlist, setUnitlist] = useState([]);
  const [meterlist, setMeterlist] = useState([]);
  const [factorylist, setFactorylist] = useState([]);
  const [treeViewList, setTreeViewList] = useState([]);
  const [unit, setunit] = useState("");
  const [meter, setMeter] = useState("");
  const [selectedLF, setSelectedLF] = useState("");
  const [lfType, setLfType] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [meterIdsArray, setMeterIdsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "meterId",
    direction: "asc",
  });
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [consumerNo, setConsumerNo] = useState([]);
  const [currentPage, setCurrentPage] = useState(() => {
    const storedLimit = parseInt(JSON.parse(localStorage.getItem("powerConsumptionCurrentPage")));
    console.log({ storedLimit })
    return storedLimit || 1;
  });
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem("recordsPerPage"), 10);
    return storedLimit || 10;
  });
  const [totalCount, setTotalCount] = useState(null);
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [discoms, setDiscoms] = useState([]);
  const [discomCode, setDiscomcode] = useState([]);
  const [consumerNoWRTDiscom, setConsumerNoWRTDiscom] = useState([]);
  const [consumerNoOptions, setConsumerNameOptions] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [search, setSearch] = useState(true);
  const headers = [
    "Consumer Name",
    "State",
    "Discom Bill OR Vendor Bill",
    "Name of Discom OR Vendor",
    "Consumer Id",
    "Location / PinCode",
    "Month",
    "Year",
    "UnitsConsumed( kVah )",
    "TotalBilled(in Rs)",
    "TotalBilledRate",
    "Total Current Bill",
    "EnergyRate",
    "DueDate",
    "Due amount to be paid",
    "Early payment date",
    "Due amount with early payment discount",
    "Load Factor",
    "Power Factor",
    "PF Incentive Availed",
    "PF Penalty Levied",
    "Scope-2 emission",
    "Contract Demand",
    "Tariff category",
    "Tariff Type",
    "Consumer Type",
    "GreenPower Tariff Consumption",
    "GreenPower Tariff Cost"
  ]; 
  const [selectedHeaders, setSelectedHeaders] = useState(headers);
  const [filters, setFilters] = useState({
    organisationId: "",
    factoryId: "",
    unitId: "",
    meterId: "",
    lfPercentage: "",
    lfType: "lteq",
    startDate: "",
    endDate: "",
  });

  var LoadFactors = [
    { value: "10", label: "10%" },
    { value: "20", label: "20%" },
    { value: "30", label: "30%" },
    { value: "40", label: "40%" },
    { value: "50", label: "50%" },
    { value: "60", label: "60%" },
    { value: "70", label: "70%" },
    { value: "80", label: "80%" },
    { value: "90", label: "90%" },
    { value: "100", label: "100%" },
  ];

    const tableHeaders = {
      consumerId: "Consumer Number",
      consumerName: "Consumer Name",
      billMonthOfLastDay: "Bill Date",
      "units.value": "Consumption (KVAH)",
      "totalBillAmount.value": "Total Bill (Rs.)",
      "stateCode": "State Code",
      "discomCode": "Discom Code",
      "rate.value": "Rate (per/unit)",
      "LF.value": "Load Factor",
      action: "Action",
    };

  const convertToStartDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Create a moment object for the first day of the specified month (UTC)
    const firstDayOfMonth = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("day");
    return firstDayOfMonth.toDate();
  };

  const convertToEndDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Convert to the last day of the month using momentP
    const date = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("month")
      .toDate();
    return date;
  };


  useEffect(() => {

    if (currentPage * limit >= totalCount && totalCount !== null) {
      console.log({ currentpageUpdated: currentPage, totalCount: totalCount, limit })
      setCurrentPage(1);
    }
  }, [totalCount])

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const filter = query.get("filter");
    if (filter === "true" && states.length > 0) {
      console.log({ filterslocal: localStorage.getItem("filter") });
      const filterData = JSON.parse(localStorage.getItem("filter"));
      const filteredStateCodes = filterData["stateCode"];
      const filteredlfPercentage = filterData["lfPercentage"];
      const filteredStartDate = filterData["startDate"];
      const filteredEndDate = filterData["endDate"];
      if (filteredStateCodes && filteredStateCodes.length > 0) {
        const matchingStates = states.filter((state) =>
          filteredStateCodes.includes(state.code)
        );
        const updatedStateCode = matchingStates.map((state) => {
          return { label: state.name, value: state.code };
        });
        const updatedStateCodeIds = matchingStates.map((state) => {
          return state._id;
        });
        setStateId(updatedStateCodeIds);
        setStateCode(updatedStateCode);
      }
      if (filteredlfPercentage && filteredlfPercentage !== "") {
        setSelectedLF(filteredlfPercentage);
      }
      if (filteredStartDate && filteredStartDate !== "") {
        const formattedStartDate = convertToStartDate(filteredStartDate);
        setStartDate(formattedStartDate);
      }
      if (filteredEndDate && filteredEndDate !== "") {
        const formattedEndDate = convertToEndDate(filteredEndDate);
        setEndDate(formattedEndDate);
      }
    } else {
      const orgFromQuery = query.get("org");
      const stateFromQuery = query.get("state");
      const consumersFromQuery = query.get("consumers");
      const loadFactorFromQuery = query.get("lf");
      const lfTypeFromQuery = query.get("lfType");
      const startDateFromQuery = query.get("startDate");
      const endDateFromQuery = query.get("endDate");
      if (stateFromQuery && stateFromQuery !== "") {
        const sanitizedStateFromQuery = stateFromQuery.replace(/[\[\]]/g, "");
        const matchingStates = states.filter((state) =>
          sanitizedStateFromQuery.includes(state.code)
        );
        const updatedStateCode = matchingStates.map((state) => {
          return { label: state.name, value: state.code };
        });
        const updatedStateCodeIds = matchingStates.map((state) => {
          return state._id;
        });

        setStateId(updatedStateCodeIds);
        setStateCode(updatedStateCode);

        if (sanitizedStateFromQuery.length <= 0) {
          const matchingStates = states;
          const updatedStateCode = matchingStates.map((state) => {
            return { label: state.name, value: state.code };
          });
          const updatedStateCodeIds = matchingStates.map((state) => {
            return state._id;
          });
          setStateId(updatedStateCodeIds);
          setStateCode(updatedStateCode);
        }
      }
      const formattedStartDate = startDateFromQuery
        ? convertToStartDate(startDateFromQuery)
        : null;

      const formattedEndDate = endDateFromQuery
        ? convertToEndDate(endDateFromQuery)
        : null;

      //   setOrg(orgFromQuery);

     
      if(lfTypeFromQuery){
        setSelectedLF(loadFactorFromQuery);
        setLfType(lfTypeFromQuery);
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
      let updatedFilters = {};
      if (orgFromQuery) {
        updatedFilters.organisationId = orgFromQuery;
      }
      if (stateFromQuery) {
        const data = stateCode.map(state => state.value);
        updatedFilters.stateCode = data;
      }
      if (loadFactorFromQuery) {
        updatedFilters.lfPercentage = parseInt(loadFactorFromQuery);
      }
      if (formattedStartDate) {
        updatedFilters.startDate = formattedStartDate;
      }
      if (formattedEndDate) {
        updatedFilters.endDate = formattedEndDate;
      }
      setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
    }
  }, [location.search, states]);

  useEffect(() => {
    const fetchData = async () => {
      if (search) {
        console.log({ filters });
        setLoading(true);

        let queryParams;
        const query = new URLSearchParams(location.search);
        const filter = query.get("filter");

        if (filter === "true" && initialLoad) {
          const filterData = JSON.parse(localStorage.getItem("filter"));
          console.log({ filterData });
          queryParams = {
            ...filterData,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction === "asc" ? "asc" : "desc",
            page: currentPage,
            pageSize: limit,
            buyerId: localStorage.getItem("userId"),
          };
        }
        else {
          queryParams = {
            ...filters,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction === "asc" ? "asc" : "desc",
            page: currentPage,
            pageSize: limit,
            buyerId: localStorage.getItem("userId"),
          };
        }
        const powerConsumptionData = await getPowerConsumptionList(queryParams);
        const data = powerConsumptionData?.data?.results;
        setTotalCount(powerConsumptionData?.data?.total);
        const meterIds = data?.map((item) => item?.meterId?._id);
        setMeterIdsArray(meterIds);
        const tableData = data.map((item) => ({
          "Consumer No": item?.meterId?.consumerNo,
          "Consumer Name": item?.factoryId?.name,
          "Bill Date": new Date(item?.billMonthOfLastDay).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "short" }
          ),
          "Consumption (KVAH)": item?.units?.value,
          "Total Bill (Rs.)": item?.totalBillAmount?.value,
          "Rate (per/unit)": item?.rate?.value,
          "Load Factor": item?.LF?.value,
          "State Code": item.stateCode,
          "Discom Code": item.discomCode,
          "Consumer Number": item?.meterId?.consumerNo,
          billUploadSoruce: item.billUploadSoruce,
          "Previous Reading Date": new Date(
            item?.billMonthOfLastDay
          ).toLocaleDateString("en-US", { year: "numeric", month: "2-digit" }),
          "Current Reading Date": new Date(
            item?.billMonthOfLastDay
          ).toLocaleDateString("en-US", { year: "numeric", month: "2-digit" }),
        }));
        // console.log(tableData,'tableData')
        const consumerNos = data?.map((item) => ({
          consumerNo: item?.meterId?.consumerNo,
        }));
        setConsumerNos(consumerNos);
        setData(tableData);
        setTotalData(tableData);
        setLoading(false);
        setSearch(false);
        setInitialLoad(false);
      }
    };
    
    const query = new URLSearchParams(location.search);
    const stateFromQuery = query.get("from");
    const consumersFromQuery = query.get("consumers");
    let parsedConsumers;

    if (consumersFromQuery && consumersFromQuery.startsWith('[') && consumersFromQuery.endsWith(']')) {
      const fixedConsumersString = consumersFromQuery.replace(/(\w+)/g, '"$1"');
      parsedConsumers = JSON.parse(fixedConsumersString);
      filters.meterId=parsedConsumers;
    }

    console.log({ parsedConsumers });
    if (stateFromQuery && !parsedConsumers ) {

      if (consumerNo.length > 0 && filters) {
        fetchData();
      }
    } else if (consumersFromQuery && parsedConsumers.length === consumerNo.length) {
      if (consumerNo.length > 0 && filters ) {
        fetchData();
      }
    }
    else {
      
   
        fetchData();
      
    }
  }, [filters, sortConfig, currentPage, search, limit]);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const stateFromQuery = query.get("consumers");
  //   if (stateFromQuery) {
  //     if (consumerNo.length > 0) {
  //       handleSearch();
  //     }
  //   }
  // }, [consumerNo]);

  useEffect(() => {
    const getdata = async () => {
      const allStateData = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);

  useEffect(() => {
    setUnitlist([]);
    setunit("");
    setfactory("");

    const getTreeViewData = async () => {
      if (org !== "" && org !== null) {
        const response = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`
        );
        const allFactories = response?.data?.factories?.flatMap(
          (factories) => factories || []
        );
        setFactorylist(allFactories);
      }
    };
    getTreeViewData();
  }, [org]);

  useEffect(() => {
    const selectedFactoryIds = factory;

    const filteredFactories = factorylist?.filter((factory) =>
      selectedFactoryIds?.includes(factory?._id)
    );
    setUnitlist(filteredFactories?.flatMap((factory) => factory.units));
  }, [factory]);

  useEffect(() => {
    const selectedUnitIds = unitlist?.map((unit) => unit._id);
    const filteredUnits = unitlist?.filter((unit) =>
      selectedUnitIds?.includes(unit?._id)
    );
    setMeterlist(filteredUnits?.flatMap((unit) => unit.meters));
  }, [unit]);

  useEffect(() => {
    const getMeterData = async () => {
      if (unit !== "") {
        const meterData = {
          unitId: unit,
        };
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/meter/list`,
          meterData
        );
        setMeterlist(response?.data?.data?.results);
      }
    };
    getMeterData();
  }, [unit]);

  const handleView = (conNo, startDate, endDate) => {
    localStorage.setItem("filter", JSON.stringify(filters));
    console.log({link: `/compare-ocr-bills?conNo=${conNo}&startDate=${startDate}&endDate=${endDate}&redirect=true`});
    
    navigate(
      `/compare-ocr-bills?conNo=${conNo}&startDate=${startDate}&endDate=${endDate}&redirect=true`
    );
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    console.log({ key, direction });
    // Update sorting configuration
    setSortConfig({ key, direction });
    handleSearch();
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <FontAwesomeIcon icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon icon={faArrowDown} />
      );
    }
    return null;
  };

  const formateDatetoMonth = (dateString) => {
    return moment(dateString).format("MM/YYYY");
  };

  const handleSearch = async () => {
    const query = new URLSearchParams(location.search);
    console.log(startDate)
    const stateFromQuery = query.get("from");
    if(stateFromQuery){
        navigate("/power-consumption");
    }
    setCurrentPage(1);

    setFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      if (org) {
        updatedFilters.organisationId = org;
      }
      if (factory) {
        updatedFilters.factoryId = factory;
      }
      if (unit) {
        updatedFilters.unitId = unit;
      }
      if (meter) {
        updatedFilters.meterId = meter;
      }
      if (stateCode && stateCode.length > 0) {
        const stateCodeIds = stateCode.map((state, i) => state.value);
        updatedFilters.stateCode = stateCodeIds;
      }
      if (discomCode && discomCode.length > 0) {
        const discomCodeIds = discomCode.map((discom, i) => discom.value);
        updatedFilters.discomCode = discomCodeIds;
      }
      if (consumerNo && consumerNo.length > 0) {
        const consumenNoIds = consumerNo.map((consumer, i) => consumer.value);
        updatedFilters.consumerNo = consumenNoIds;
      }
      if (selectedLF) {
        updatedFilters.lfPercentage = parseInt(selectedLF);
      }
      if (startDate) {
        updatedFilters.startDate = formateDatetoMonth(startDate);
      }
      if (endDate) {
        updatedFilters.endDate = formateDatetoMonth(endDate);
      }
      setSearch(true);
      return updatedFilters;
    });
  };

  const handleReset = () => {
    const query = new URLSearchParams(location.search);
    const stateFromQuery = query.get("consumers");
    console.log({ stateFromQuery });
    if (stateFromQuery) {
      navigate("/power-consumption");
      handleSearch();
      setUnitlist([]);
      setMeterlist([]);
      setFactorylist([]);
      setStateCode([]);
      setDiscomcode([]);
      setConsumerNo([]);
      setOrg("");
      setfactory("");
      setunit("");
      setMeter("");
      setSelectedLF("");
      setStartDate("");
      setEndDate("");
      setFilteredData([]);
      setSortConfig({ key: "meterId", direction: "asc" });
      setFilters({
        organisationId: "",
        factoryId: "",
        unitId: "",
        meterId: "",
        startDate: "",
        endDate: "",
      });
    } else {
      navigate("/power-consumption");
      handleSearch();
      setUnitlist([]);
      setMeterlist([]);
      setFactorylist([]);
      setStateCode([]);
      setDiscomcode([]);
      setConsumerNo([]);
      setOrg("");
      setfactory("");
      setunit("");
      setMeter("");
      setSelectedLF("");
      setStartDate("");
      setEndDate("");
      setFilteredData([]);
      setSortConfig({ key: "meterId", direction: "asc" });
      setFilters({
        organisationId: "",
        factoryId: "",
        unitId: "",
        meterId: "",
        startDate: "",
        endDate: "",
      });
    }
  };

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    const getStateList = async () => {
      const response = await fetchStateList();
      const stateList = response?.data?.results;

      if (stateList) {
        const sortedStateList = stateList.sort((a, b) => {
          if (a.code === "IN-MH") return -1;
          if (b.code === "IN-MH") return 1;
          return a.name.localeCompare(b.name);
        });
        setStates(sortedStateList);
      }
    };

    getStateList();
  }, []);

  useEffect(() => {
    if (stateId && consumerNoOptions.length > 0) {
      console.log({ stateId });
      const getDiscomList = async () => {
        const payload = {
          data: {
            stateId,
          },
        };
        const response = await fetchDiscomList(payload);
        const discomList = response?.data?.results;
        if (discomList) {
          const sortedDiscomList = discomList.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setDiscoms(sortedDiscomList);
          const query = new URLSearchParams(location.search);
          const filter = query.get("filter");
          const stateFilter = query.get("state");

          if (filter === "true" && sortedDiscomList.length > 0) {
            const filterData = JSON.parse(localStorage.getItem("filter"));
            const filteredDiscomCodes = filterData["discomCode"];
            const filteredConsumerNos = filterData["consumerNo"];
            if (filteredDiscomCodes && filteredDiscomCodes.length > 0) {
              let matchingDiscoms = sortedDiscomList.filter((dis) =>
                filteredDiscomCodes.includes(dis.code)
              );
              const updatedDiscomCode = matchingDiscoms.map((dis) => {
                return { label: dis.name, value: dis.code };
              });
              console.log({ updatedDiscomCode });
              setDiscomcode(updatedDiscomCode);
              //   updated consumers
              console.log({ consumerNoOptions });
              const selectedDiscomCodes = updatedDiscomCode.map(
                (discom) => discom.value
              );
              setConsumerNo([]);
              setFilters({
                ...filters,
                discomCode: selectedDiscomCodes,
                consumerNo: [],
              });
              const uniqueConsumerOptions = consumerNoOptions.reduce(
                (accumulator, current) => {
                  if (selectedDiscomCodes.includes(current.discomCode)) {
                    accumulator.push({
                      label: current.label,
                      id: current._id,
                      value: current.value,
                      stateCode: current.stateCode,
                      discomCode: current.discomCode,
                    });
                  }
                  return accumulator;
                },
                []
              );
              console.log({ uniqueConsumerOptions });
              setConsumerNoWRTDiscom(uniqueConsumerOptions);
              if (filteredConsumerNos && filteredConsumerNos.length > 0) {
                const consumerNoToBeSelected = uniqueConsumerOptions.filter(
                  (dis) => filteredConsumerNos.includes(dis.value)
                );
                setConsumerNo(consumerNoToBeSelected);
              }
            }
          }

          if (stateFilter) {
            let matchingDiscoms = sortedDiscomList;
            const updatedDiscomCode = matchingDiscoms.map((dis) => {
              return { label: dis.name, value: dis.code };
            });
            setDiscomcode(updatedDiscomCode);
            const selectedDiscomCodes = updatedDiscomCode.map(
              (discom) => discom.value
            );
            setConsumerNo([]);
            setFilters({
              ...filters,
              discomCode: selectedDiscomCodes,
              consumerNo: [],
            });
            const uniqueConsumerOptions = consumerNoOptions.reduce(
              (accumulator, current) => {
                if (selectedDiscomCodes.includes(current.discomCode)) {
                  accumulator.push({
                    id: current.id,
                    label: current.label,
                    value: current.value,
                    stateCode: current.stateCode,
                    discomCode: current.discomCode,
                  });
                }
                return accumulator;
              },
              []
            );
            setConsumerNoWRTDiscom(uniqueConsumerOptions);
            setConsumerNo(uniqueConsumerOptions);
            const consumersFromQuery = query.get("consumers");
            if (consumersFromQuery) {
              const consumers = getconsumernofromstring(consumersFromQuery);
              const consumerToBeSelected = uniqueConsumerOptions.filter((con) =>
                consumers.includes(con.id)
              );
              setConsumerNo(consumerToBeSelected);
            } else {
              handleSearch();
            }
          }
        }
      };
      getDiscomList();
    } else {
      setDiscoms([]);
    }
  }, [stateId, consumerNoOptions]);

  const getconsumernofromstring = (consumersFromQuery) => {
    let fixedConsumersString = consumersFromQuery
      .replace(/^\[|\]$/g, "") // Remove the surrounding brackets
      .split(",") // Split string into array
      .map((id) => id.trim()) // Trim any extra spaces
      .map((id) => `"${id}"`) // Add double quotes around each ID
      .join(","); // Join back into a comma-separated string

    console.log({ fixedConsumersString });
    let consumersArray;
    try {
      consumersArray = JSON.parse(`[${fixedConsumersString}]`);
    } catch (e) {
      console.error("Error parsing consumersFromQuery:", e);
      consumersArray = []; // Default to empty array on error
    }
    return consumersArray;
  };

  const handleStateChange = (selectedState) => {
    setInitialLoad(false);
    const { stateIds, stateCodes } = selectedState.reduce(
      (acc, selected) => {
        const foundState = states.find(
          (state) => state.code === selected.value
        );
        if (foundState) {
          acc.stateIds.push(foundState._id);
          acc.stateCodes.push(foundState.code);
        }
        return acc;
      },
      { stateIds: [], stateCodes: [] }
    );
    setStateCode(selectedState);
    setFilters({
      ...filters,
      stateCode: stateCodes,
      discomCode: [],
      consumerNo: [],
    });
    setStateId(stateIds);
    setDiscomcode([]);
    setConsumerNo([]);
    if (selectedState.length === 0) {
      setConsumerNoWRTDiscom(consumerNoOptions);
    } else {
      const uniqueConsumerOptions = consumerNoOptions.reduce(
        (accumulator, current) => {
          if (stateCodes.includes(current.stateCode)) {
            accumulator.push({
              id: current._id,
              label: current.label,
              value: current.value,
              stateCode: current.stateCode,
              discomCode: current.discomCode,
            });
          }
          return accumulator;
        },
        []
      );
      setConsumerNoWRTDiscom(uniqueConsumerOptions);
    }
  };

  const handleDiscomChange = (selectedDiscom) => {
    setInitialLoad(false);
    const selectedDiscomCodes = selectedDiscom.map((discom) => discom.value);
    setConsumerNo([]);
    setDiscomcode(selectedDiscom);
    setFilters({ ...filters, discomCode: selectedDiscomCodes, consumerNo: [] });
    const uniqueConsumerOptions = consumerNoOptions.reduce(
      (accumulator, current) => {
        if (selectedDiscomCodes.includes(current.discomCode)) {
          accumulator.push({
            id: current._id,
            label: current.label,
            value: current.value,
            stateCode: current.stateCode,
            discomCode: current.discomCode,
          });
        }
        return accumulator;
      },
      []
    );
    setConsumerNoWRTDiscom(uniqueConsumerOptions);
  };

  const handleConsumerNoChange = (event) => {
    setInitialLoad(false);
    console.log({ event });
    setConsumerNo(event);
    const selectedConsumerNo = event.map((discom) => discom.value);
    console.log(selectedConsumerNo);
    setFilters({ ...filters, consumerNo: selectedConsumerNo });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organisationsResponse = await postList(
          `${process.env.REACT_APP_API_URL}/organisation/list`
        );
        const organisationsList = organisationsResponse?.data?.results;
        const organisationIds = organisationsList.map((org) => org._id);
        if (organisationIds?.length > 0) {
          const organisationsData = await Promise.all(
            organisationIds.map((orgId) =>
              getData(
                `${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}`
              )
            )
          );

          const allFactories = organisationsData?.flatMap((data) =>
            (data?.data?.factories || []).map((factory) => ({
              ...factory,
              organisationId: data.data._id,
              name: data.name,
            }))
          );

          const allUnits = allFactories?.flatMap((data) =>
            (data?.units || []).map((unit) => ({
              ...unit,
              factoryId: data._id,
            }))
          );

          const allMeters = allUnits?.flatMap((data) =>
            (data?.meters || []).map((meter) => ({
              ...meter,
              unitId: data._id,
              name: data.name,
              stateCode: data.stateCode,
              discomCode: data.discomCode,
            }))
          );

          const uniqueConsumerOptions = allMeters.reduce(
            (accumulator, current) => {
              if (
                !accumulator.some(
                  (option) => option.value === current.consumerNo
                )
              ) {
                accumulator.push({
                  id: current._id,
                  label: current.consumerNo + " (" + current.name + ")",
                  value: current.consumerNo,
                  stateCode: current.stateCode,
                  discomCode: current.discomCode,
                });
              }
              return accumulator;
            },
            []
          );
          console.log({ uniqueConsumerOptions });
          setConsumerNameOptions(uniqueConsumerOptions);
        }
        // setLoading(false);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSetPageLimit = (event) => {
    setLimit(Number(event.target.value));
    localStorage.setItem("recordsPerPage", event.target.value);
    handleSearch();
  };

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  const handleDownload = async () => {
    try {
        let updatedFilters = {...filters,column: selectedHeaders};
      const response = await fetch(
        `${process.env.REACT_APP_API_URL
        }/powerConsumtion/download-report?buyerId=${localStorage.getItem(
          "userId"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure headers indicate a JSON request body
          },
          body: JSON.stringify(updatedFilters),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const contentDisposition = response.headers.get("content-disposition");
      let filename = `${getCurrentDateTime()}.csv`;
      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match.length === 2) filename = match[1];
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef(null);
  useEffect(() => {
    console.log({ tableHeight });
  }, [tableHeight]);
  // Function to dynamically calculate height
  const calculateHeight = () => {
    const windowHeight = window.innerHeight - 400;
    // Subtracting top position to get available height
    const availableHeight = windowHeight; // Adding margin for padding if necessary
    setTableHeight(availableHeight);
  };

  useEffect(() => {
    // Calculate height on component mount
    calculateHeight();

    // Add event listener to handle window resize
    window.addEventListener("resize", calculateHeight);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const updateFilterFromQuery = query.get("filter");
    if (updateFilterFromQuery === "true" && initialLoad) {
      const savedFilters = localStorage.getItem("filter");
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        setFilters(parsedFilters);
      }
    }
  }, []);

  useEffect(() => {
    // handleSearch();
    setSearch(true);
    // handleSearch();
  }, [currentPage])


  return (
    <div className="max-w-[1920px] relative mt-[70px] flex ">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${isSidebarOpen ? "sm:pl-[315px]" : "sm:pl-[95px]"
          } w-[100%] px-2 mb-8 mr-1 transition-width duration-300`}
      >
            
        <div className=" rounded-lg pb-[5px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[30px]">
    
          <div className="flex flex-row justify-between">
            <div className="px-4 pt-4 pb-2 text-[20px] sm:text-[28px] font-medium leading-[42px]">
              Power Consumption List
            </div>
            <div className="flex gap-2  h-[60px] sm:h-auto sm:leading-[42px] py-2 pr-0 sm:pr-2 ">
              <GoTODashboard />
              <CsvFieldSelector  selectedHeaders={selectedHeaders} setSelectedHeaders={setSelectedHeaders} handleDownload={handleDownload} headers={headers} />
              <ToastContainer/>
            </div>
          </div>
          <div className="flex flex-wrap gap-2 px-2 justify-between">
            {/* <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <FilterSelect
                label={"Organisation"}
                value={org}
                onChange={(e) => {
                  setOrg(e.target.value);
                }}
                options={organisationList?.map((organisation) => {
                  return { label: organisation.name, value: organisation._id };
                })}
                maxWidth={120}
              />
            </div> */}
            {/* <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <FilterSelect
                label={"Factory"}
                value={factory}
                onChange={(e) => {
                  setfactory(e.target.value);
                }}
                options={factorylist?.map((factory) => {
                  return { label: factory.name, value: factory._id };
                })}
              />
            </div> */}
            <div className="border rounded-[10px] outline-none border-gray-500  sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <MultiSelect
                options={states?.map((state) => {
                  return { label: state?.name, value: state?.code };
                })}
                value={stateCode ?? []}
                onChange={handleStateChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select state code",
                  allItemsAreSelected: "All States selected",
                }}
                disableSearch={false}
                className="custom-multi-select"
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500  sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <MultiSelect
                options={discoms?.map((discom) => {
                  return { label: discom?.name, value: discom?.code };
                })}
                value={discomCode ?? []}
                onChange={handleDiscomChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select discom code",
                  allItemsAreSelected: "All Discoms selected",
                }}
                disableSearch={false}
                className="custom-multi-select"
              />
            </div>
            {/* <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 max-w-[180px] w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <FilterSelect
                label={"Unit"}
                value={unit}
                onChange={(e) => {
                  setunit(e.target.value);
                }}
                options={unitlist?.map((unit) => {
                  return { label: unit.name, value: unit._id };
                })}
              />
            </div> */}
            <div className="border rounded-[10px] outline-none border-gray-500  sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <MultiSelect
                options={consumerNoWRTDiscom}
                value={consumerNo ?? []}
                onChange={handleConsumerNoChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select Consumer No",
                  allItemsAreSelected: "All Consumer Nos selected",
                }}
                disableSearch={false}
                className="custom-multi-select"
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-800 text-black  sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[15.5%]">
              <FilterSelect
                options={LoadFactors?.map((LF) => {
                  return { label: LF.label, value: LF.value };
                })}
                value={selectedLF}
                onChange={(e) => {
                  setSelectedLF(e.target.value);
                }}
                label={"Load Factor"}
              />
            </div>
            <div className="lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] z-[40] sm:w-[40%] lg:w-[15.5%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  placeholderText="Select Start Month"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  showMonthYearPicker
                  dateFormat="MMM-yyyy"
                  startDate={startDate}
                  endDate={endDate}
                  className="w-full"
                />
              </div>
            </div>
            <div className="lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] z-[40] sm:w-[40%] lg:w-[15.5%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  placeholderText="Select End Month"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  showMonthYearPicker
                  dateFormat="MMM-yyyy"
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row mt-1 justify-between px-2 item-center">
            {
              <div className="flex justify-end items-center text-sm  mx-2 md:mx-6 mt-4">
                {loading ? "" : `Total Consumptions ${totalCount}`}
              </div>
            }
            <div className="flex justify-end my-2 mx-2 md:mx-6">
              <button
                onClick={handleSearch}
                className="text-white bg-[#067C4E] hover:bg-[#045036] w-[100px] py-2 px-4 rounded-lg"
              >
                Filter
              </button>
              <button
                onClick={handleReset}
                className="bg-white border-[#067C4E] hover:bg-gray-200 text-[#067C4E] border-2 border-solid w-[100px] py-2 px-4 ml-3 rounded-lg"
              >
                Reset
              </button>
            </div>
          </div>
          {!loading ? (
            <div>
              <div className="w-full relative">
                <div className={`overflow-x-auto  `}>
                  <table className="w-full mt-2 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] border-[#EFEFEF] text-center ">
                    <thead className="bg-[#EFEFEF] sticky top-0 z-10">
                      <tr>
                        {Object.entries(tableHeaders)?.map(
                          ([key, value], index) => (
                            <th
                              key={index}
                              className="cursor-pointer font-medium w-[10%] px-4 py-2"
                              onClick={() => sortData(key)}
                            >
                              {value} {renderSortArrow(key)}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                  </table>
                </div>

                <div
                  style={{ height: `${tableHeight}px` }}
                  className={`overflow-y-auto upload-bills  `}
                >
                  <table className="w-full mt-2 text-center">
                    <tbody>
                      {data?.length === 0 ? (
                        <tr>
                          <td
                            colSpan={Object.keys(tableHeaders).length}
                            className="px-6 py-4 text-sm leading-5 text-gray-500 text-center"
                          >
                            No Data Available
                          </td>
                        </tr>
                      ) : (
                        data?.map((rowData, rowIndex) => (
                          <tr
                            key={rowIndex}
                            className="h-[50px] text-gray-800 font-thin"
                          >
                            {Object.entries(tableHeaders)?.map(
                              ([key, header], cellIndex) => {
                                if (key === "action") {
                                  return (
                                    <td key={cellIndex} className="p-1 w-[10%]">
                                      {rowData?.billUploadSoruce === "ocr" && (
                                        <button
                                          className="cursor-pointer text-[blue] underline px-3 py-1"
                                          onClick={() =>
                                            handleView(
                                              rowData["Consumer Number"],
                                              rowData["Previous Reading Date"],
                                              rowData["Current Reading Date"]
                                            )
                                          }
                                        >
                                          View Bill
                                        </button>
                                      )}
                                    </td>
                                  );
                                }
                                const value = rowData[header];
                                return (
                                  <td key={cellIndex} className="p-1 w-[10%]">
                                    {header === "Organisation Name" ||
                                      header === "Factory Name" ||
                                      header === "Unit Name" ||
                                      header === "Bill Date"
                                      ? capitalizeWords(value)
                                      : value}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {data?.length !== 0 && (
                <div>
                  <div className="pagination-controls border-t-2 border-[#abaaaa] flex flex-row items-center justify-between pt-1">
                    <div className="mr-6">
                      <label htmlFor="itemsPerPage" className="mr-2 ml-2">
                        Items per page:
                      </label>
                      <select
                        id="itemsPerPage"
                        value={limit}
                        onChange={handleSetPageLimit}
                        className="px-2 py-1 rounded-lg border border-gray-300"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <div>
                      <button
                        className={`bg-[#e1e0e0] px-2 py-1 rounded-md`}
                        onClick={() => { setCurrentPage(currentPage - 1); localStorage.setItem('powerConsumptionCurrentPage', currentPage - 1) }}

                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      {/* <span className="mx-4">{Math.min(currentPage * limit, totalCount)-9} to {Math.min(currentPage * limit, totalCount)} off {totalCount}</span> */}
                      <span className="mx-4">
                        {/* {`${(currentPage - 1) * limit + 1} to ${Math.min(currentPage * limit, totalCount)} of ${totalCount}`} */}
                        {totalCount > 0
                          ? `${(currentPage - 1) * limit + 1} to ${Math.min(
                            currentPage * limit,
                            totalCount
                          )} of ${totalCount}`
                          : "0"}
                      </span>

                      <button
                        className="bg-[#e1e0e0]  mr-2 px-2 py-1 rounded-md"
                        disabled={currentPage * limit >= totalCount}
                        onClick={() => { setCurrentPage(currentPage + 1); localStorage.setItem('powerConsumptionCurrentPage', currentPage + 1) }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="min-h-[50vh] flex justify-center items-center">
              <LoaderComponent />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PowerConsumption;
