import React, { useState, useEffect, useRef } from "react";
import LineChartEnergyView2Y from "../../containers/graphs/LineChartEnergyView2Y";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import {
  faChartSimple,
  faExpand,
  faMinus,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";

const constantUtils = new ConstantUtils();
const billCosmptionService = new BillConsumptionService();

const EnergyMonthlyView = ({
  selectedEnergyGraph,
  selectedEnergyYear,
  energyMonthlyData,
  energyBillingDemand,
  selectedUnit,
  slotConfigData
}) => {
//  console.log({ selectedUnit, unitList });

  const [isOpenMonthlyEnergyDropwonView, setIsOpenMonthlyEnergyDropwonView] =
    useState(false);

  const [energyData, setEnergyData] = useState([]);
  const handleEnergyMenuClick = () => {
    setIsOpenMonthlyEnergyDropwonView(!isOpenMonthlyEnergyDropwonView);
  };

  const [options, setOptions] = useState({
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,

          text: selectedEnergyYear,
        },
        position: "bottom",
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Energy(kWh)", // Add your label here
        },
        grid: {
          display: false,
        },
        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }


      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
          display: false,
        },
        title: {
          display: true,
          text: "Demand(kva)",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      colors: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      
 tooltip: {
    callbacks: {
      label: function (context) {
        const value = context.raw;
        return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');;
      },
    },
  },

    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        x: {
          ...prevOptions.scales.x,
          title: {
            ...prevOptions.scales.x.title,
            text: billCosmptionService.getFiscalYearLabel(parseInt(selectedEnergyYear) ),
          },
          position: "bottom",
        },
      },
    }));
  }, [selectedEnergyYear]);

  const [lableValue, setLableValue] = useState(
    constantUtils.getAllStaticMonthsFromApril()
  );

  const [labels, setLabels] = useState(
    constantUtils.getMonthsFromAprilFinancial(selectedEnergyYear)
  );

  const [data, setdata] = useState({
    labels,
    datasets: [
      {
        label: "Billed Demand",
        data:
          energyBillingDemand && energyBillingDemand.data
            ? energyBillingDemand.data
            : [], // replace with your actual data
        type: "line",
        backgroundColor: constantUtils.getGraphColors().peakDemand,
        borderColor: constantUtils.getGraphColors().peakDemand,
        fill: false,
        yAxisID: "y1",
        order: 1,
      },
    ],
  });

useEffect(() => {
  setLabels(constantUtils.getMonthsFromAprilFinancial(selectedEnergyYear));
}, [selectedEnergyYear])


  useEffect(() => {
    setdata((prevData) => ({
        ...prevData,
        labels: labels,
    }));
}, [labels]);

  

  useEffect(() => {
//    console.log({ energyBillingDemand });
    if (energyBillingDemand && energyBillingDemand.data) {
//      console.log({ energyBillingDemand });
//      console.log({ costBillingDemand: energyBillingDemand.data });

      setdata((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => {
          if (dataset.label === "Billed Demand") {
            return {
              ...dataset,
              data:
                energyBillingDemand && energyBillingDemand.data
                  ? energyBillingDemand.data
                  : [],
            };
          }
          return dataset;
        }),
      }));
    }
  }, [energyBillingDemand]);

  useEffect(() => {
//    console.log({ energyMonthlyData:  energyMonthlyData[selectedEnergyYear]["energyMonthLyData"][selectedUnit] });
    if (Object.keys(energyMonthlyData).length !== 0) {
        console.log({energyMonthlyData})
      if (
        energyMonthlyData !== undefined &&
        energyMonthlyData[selectedEnergyYear] &&
        energyMonthlyData[selectedEnergyYear][selectedUnit] &&
        energyMonthlyData[selectedEnergyYear][selectedUnit][
          "energyMonthLyData"
        ] &&
        energyMonthlyData[selectedEnergyYear][selectedUnit]["energyMonthLyData"]
          .length > 0
      ) {

        setdata((prevData) => {
          let newDatasets = prevData.datasets
            .map((dataset) => {
              if (dataset && dataset.label === "Billed Demand") {
                return dataset;
              }
            })
            .filter(Boolean);
        //  console.log({ newDatasets });
          let dynamicDatasets =
            energyMonthlyData[selectedEnergyYear][selectedUnit][
              "energyMonthLyData"
            ];
          newDatasets.push(...dynamicDatasets);


          return {
            ...prevData,
            datasets: newDatasets,
          };
        });
      } else {
        setdata((prevData) => {
          let newDatasets = prevData.datasets.filter((dataset) => {
            return dataset.label === "Billed Demand";
          });

          return {
            ...prevData,
            datasets: newDatasets,
          };
        });
      }
    }
  }, [energyMonthlyData, selectedUnit, selectedEnergyYear]);

  useEffect(() => {
//    console.log({data});
  }, [data]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (
      energyMonthlyData[selectedEnergyYear] !== undefined ||
      energyMonthlyData !== undefined
    ) {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);

        }, 1200);
    } else {
      setIsLoading(true);
    }
  }, [energyMonthlyData]);

  const [full, setFull] = useState(false);
  const dropdownRef = useRef(null); // Add this line

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenMonthlyEnergyDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  useEffect(() => {
    // console.log({slotConfigData})
  }, [slotConfigData])
  

  return (
    <div
      className={`${
        selectedEnergyGraph === "Monthly" ? "flex" : "hidden"
      }  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040] sm:min-h-[400px] rounded-[30px] w-full`}
    >
      <div className={`px-[20px] sm:px-[35px]`}>
        <div className={` flex justify-between items-center  py-[10px] sm:py-[25px] w-full`}>
          <div>Annual: ToD Energy Distribution : Discom  </div>

          <div ref={dropdownRef} className="cursor-pointer flex gap-[20px]">
            <button onClick={() => setFull(true)}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
            <CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().MonthlyEnergy.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().MonthlyEnergy.text}
            />
          </div>

          {/* <img onClick={handleEnergyMenuClick} alt="menuIcon" src="/graphMenuIcon.svg" />

    
          <div
            className={`${isOpenMonthlyEnergyDropwonView ? "block absolute ml-[100px]" : "hidden"}`}
          >
            <div className={`absolute  pt-[35px]`}>
              <ul
                className={` text-[16px] rounded-[10px] bg-white  font-medium leading-[24px] border py-[10px] px-[10px] shadow-[0_4px_4px_0_#00000040] ml-[-380px] border-[#D9D9D9]`}
              >
                <li
                  onClick={() =>
                    handleEnergygraph("Annual", selectedEnergyYear, "")
                  }
                  className="inline-flex cursor-pointer gap-[10px]"
                >
                  <img src="/leftCircleArrow.svg" />
                  <span>Annual Power Consumption View (KWH)</span>{" "}
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <LegendComponent data={data} columns={"grid-cols-5"} slotConfigData={slotConfigData} />
      </div>

      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="flex gap-1 justify-end py-2 px-5"> */}{" "}
          {/* {labels.map((label, index) => (
              <button
                className="bg-gray-800 text-white font-bold text-[12px]  px-2 rounded"
                key={label}
                onClick={() =>
                  handleEnergygraph(
                    "TOD",
                    selectedEnergyYear,
                    lableValue[label]
                  )
                }
              >
                {label}
              </button>
            ))} */}
          {/* </div> */}
          <div className="sm:px-[35px] pb-[20px] sm:py-[25px] w-full">
            <LineChartEnergyView2Y
              data={data}
              options={options}
              full={full}
              setFull={setFull}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EnergyMonthlyView;
