import { faCloudDownload, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CsvFieldSelector = ({ headers, handleDownload, selectedHeaders, setSelectedHeaders, py }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // Toggle the popup
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Handle checkbox selection
  const handleCheckboxChange = (header) => {
    if (selectedHeaders.includes(header)) {
      setSelectedHeaders(selectedHeaders.filter((h) => h !== header));
    } else {
      setSelectedHeaders([...selectedHeaders, header]);
    }
  };

  // Handle the Select All checkbox
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedHeaders([]); // Unselect all headers
    } else {
      setSelectedHeaders(headers);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectAll(selectedHeaders.length === headers.length);
  }, [selectedHeaders, headers]);

  const handleOnclick = () => {
    if (selectedHeaders.length === 0) {
        toast.dismiss();
        toast.error("Please select at least one Field.", {
          position: "top-right",
        });
        return;
    }else{
        handleDownload();
        toggleModal();
    }

  };

  return (
    <div>
      <div
        onClick={toggleModal}
        className={`flex border border-gray-300 text-white shadow-lg bg-[#067C4E] text-[12px] px-2 ${py ?? "py-1"} rounded-md justify-center cursor-pointer items-center gap-2`}
      >
        <FontAwesomeIcon size="2xl" icon={faCloudDownload} />
        Extract Report
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex z-50 items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="m-2">
            <div className="bg-white rounded-lg w-full sm:w-[550px] p-5">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-bold mb-4">Select Fields To Extract Reports</h2>
                <button
                  onClick={toggleModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✖️
                </button>
              </div>

              <div className="space-y-1 grid gap-1 grid-cols-3 py-4">
                <div className="flex items-center col-span-3">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label className="ml-2 text-sm text-gray-700">Select All</label>
                </div>
                {headers.map((header, index) => (
                  <div key={index} className="flex items-center py-2">
                    <input
                      type="checkbox"
                      checked={selectedHeaders.includes(header)}
                      onChange={() => handleCheckboxChange(header)}
                      className="form-checkbox min-h-5 min-w-5 text-blue-600"
                    />
                    <label className="ml-2 text-xs text-gray-700">{header}</label>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleOnclick}
                  className="mt-4 px-4 h-[40px] bg-green-700 text-white rounded hover:bg-green-900"
                >
                <FontAwesomeIcon size="xl" icon={faCloudDownload} /> &ensp;
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvFieldSelector;
