import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


const BackButton = ({ backlevel,text,px,py ,smpy ,smpx ,link}) => {
  const navigate = useNavigate();
 
  const handleNavigation = () => {
    const destination = backlevel ? backlevel : -1;
    console.log({link})
    if(link && link !== ""){
        navigate(link);
    }else{
        navigate(destination);
    }
    
  };

  return (
    <div
      onClick={handleNavigation}
      className={`flex border border-gray-300 shadow-lg ${smpy?smpy:"sm:py-2"} ${smpx?smpx:"sm:px-2"} ${px?px:"px-1"} ${py?py:"py-1"} rounded-md justify-center cursor-pointer items-center gap-2`}
    >
      <FontAwesomeIcon size="xl" icon={faCircleChevronLeft} />
      <span>{text?text: "Back"}</span>

    </div>
  );
};

export default BackButton;
