import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";
import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import {
  getData,
  postData,
  updateData,
  postList,
} from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import GooglePlacesMap from "../../components/googlePlacesMap/GooglePlacesMap";

const EditUnit = () => {
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const [factorylist, setFactorylist] = useState([]);
  const [factory, setfactory] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [existingSolarTop, setExistingSolarTop] = useState("");
  const [solarCapacity, setSolarCapacity] = useState("");
  const [solarNetBilling, setSolarNetBilling] = useState("");
  const [solarNetMetering, setSolarNetMetering] = useState("");
  const [errors, setErrors] = useState([]);
  const [discomCodeList, setDiscomCodeList] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [selectedDiscomCode, setSelectedDiscomCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [clickedCoords, setClickedCoords] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const unit = await getData(`${process.env.REACT_APP_API_URL}/unit/${id}`);
      // console.log(unit.result.factoryId.address,'result');
      if (unit.result.name) {
        setName(unit.result.name);
      }
      if (unit.result.organisationId._id) {
        setOrg(unit.result.organisationId._id);
      }
      if (unit.result.factoryId._id) {
        setfactory(unit.result.factoryId._id);
      }
      if (unit.result.existingSolarRooftop) {
        setExistingSolarTop(unit.result.existingSolarRooftop);
      }
      if (unit.result.solarRooftopDetails?.Capacity) {
        setSolarCapacity(unit.result.solarRooftopDetails?.Capacity);
      }
      if (unit.result.solarRooftopDetails?.netBilling) {
        setSolarNetBilling(unit.result.solarRooftopDetails?.netBilling);
      }
      if (unit.result.solarRooftopDetails?.netMetering) {
        setSolarNetMetering(unit.result.solarRooftopDetails?.netMetering);
      }
      if (unit.result.stateCode) {
        setSelectedStateCode(unit.result.stateCode);
      }
      if (unit.result.discomCode) {
        setSelectedDiscomCode(unit.result.discomCode);
      }
      if (unit.result.factoryId.address) {
        setAddress(unit.result.factoryId.address);
      }

      // Set longitude and latitude if location object exists
      if (unit.result.location && unit.result.location.coordinates) {
        const [latitude, longitude] = unit.result.location.coordinates;
        setLongitude(longitude);
        setLatitude(latitude);
      }
    };
    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (!selectedDiscomCode && discomCodeList.length > 0) {
      const selectedDiscom = discomCodeList.find(
        (discom) => discom.code === selectedDiscomCode
      );
      if (selectedDiscom) {
        setSelectedDiscomCode(selectedDiscom.code);
        setSelectedStateCode(selectedDiscom.stateId.code);
      }
    }
  }, [selectedDiscomCode, discomCodeList]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    // console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem("userId"),
      organisationId: org,
      factoryId: factory,
      name: name,
      location: {
        type: "Point",
        coordinates: [latitude, longitude],
      },
      existingSolarRooftop: existingSolarTop,
      solarRooftopDetails: {
        Capacity: solarCapacity,
        netBilling: solarNetBilling,
        netMetering: solarNetMetering,
      },
      stateCode: selectedStateCode,
      discomCode: selectedDiscomCode,
    };
    // console.log(allStateData,"allStateData");
    // return;
    if (
      allStateData.name != "" &&
      allStateData.organisationId != "" &&
      allStateData.factoryId != "" &&
      allStateData.buyerId != "" &&
      allStateData.existingSolarRooftop != ""
    ) {
      const response = await updateData(
        `${process.env.REACT_APP_API_URL}/unit/${id}`,
        allStateData
      );

      // console.log(response);
      if (response?.data?.statusCode === 200) {
        toast.success("Unit Successfully Updated", {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/my-data");
      } else {
        toast.error(response.error.response.data.error.message);
      }
      if (isErrorResolved) {
        setOpenPopup(true);
      }
    }
  };

  useEffect(() => {
    const getdata = async () => {
      const allStateData = {
        buyerId: localStorage.getItem("userId"),
      };
      // console.log(allStateData);
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);

  useEffect(() => {
    const getfactorydata = async () => {
      if (org !== "") {
        const orgdata = {
          organisationId: org,
        };
        // console.log(orgdata);
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/factory/list`,
          orgdata
        );
        // console.log({ response })
        setFactorylist(response?.data?.data?.results);
      }
    };
    getfactorydata();
  }, [org]);

  const handleErrors = (errorObj) => {
    // console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      setFormSubmitted(true);
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const disComCodeList = await postList(
          `${process.env.REACT_APP_API_URL}/disComCode/list`
        );
        disComCodeList.data.results.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setDiscomCodeList(disComCodeList.data.results);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // console.log(discomCodeList,'discmo')

  const handleViewOnMapClick = () => {
    // if (address) {
      setIsOverlayOpen(true);
    // } else {
    //   alert('Please Provide a Valid Address.');
    // }
  };

  const handleOverlayClose = () => {
    setIsOverlayOpen(false);
  };

  const handleMapClick = (coords) => {
    setClickedCoords(coords);
    setLongitude(coords.lng.toFixed(4));
    setLatitude(coords.lat.toFixed(4));
    // console.log('Clicked coordinates:', coords);
  };

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"}`}>
        <div className=" rounded-lg  bg-[#E2F5EC] px-4 sm:px-0 w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[22px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Edit Unit
          </div>
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter factory name"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Name"}
              value={name}
              fieldName={"Name"}
              setInputValue={setName}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Organisation"}
              isflexed={true}
              // defaultValue={"Select option"}
              validationtypes={"required"}
              imputBoxMaxWidth={545}
              showError={formSubmitted}
              fieldName={"Organisation"}
              showLable={true}
              value={org}
              onChange={(e) => {
                setOrg(e.target.value);
              }}
              options={organisationList.map((organisation) => {
                return { label: organisation.name, value: organisation._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Factory"}
              isflexed={true}
              // defaultValue={"Select factory"}
              validationtypes={"required"}
              showError={formSubmitted}
              fieldName={"Factory"}
              imputBoxMaxWidth={545}
              value={factory}
              showLable={true}
              onChange={(e) => {
                setfactory(e.target.value);
              }}
              options={factorylist.map((factory) => {
                return { label: factory.name, value: factory._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />

          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Existing Solar Rooftop"}
              validationtypes={"required"}
              isflexed={true}
              // defaultValue={"Existing Solar Rooftop"}
              showError={formSubmitted}
              fieldName={"Existing Solar Rooftop"}
              imputBoxMaxWidth={545}
              value={existingSolarTop}
              showLable={true}
              onChange={(e) => {
                setExistingSolarTop(e.target.value);
              }}
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          {existingSolarTop === "yes" && (
            <>
              <div className="flex justify-between items-center">
                <div className="pl-[30px] md:min-w-[220px]">
                  Solar Rooftop Details{" "}
                </div>
                <div className="flex justify-start w-full">
                  <div className="relative w-full max-w-[540px] ">
                    <div className="flex justify-between ">
                      <div>
                        <InputBoxV1
                          placeholder="Capacity"
                          id="test"
                          type="text"
                          // validationtypes={["required"]}
                          // showError={formSubmitted}
                          label={""}
                          maxWidth220={true}
                          value={solarCapacity}
                          // fieldName={"Capacity"}
                          displayLabel={false}
                          setInputValue={setSolarCapacity}
                          // handleErrors={handleErrors}
                        />
                      </div>
                      <div>
                        <InputBoxV1
                          placeholder="Net Billing"
                          id="test"
                          type="text"
                          // validationtypes={["required"]}
                          // showError={formSubmitted}
                          label={""}
                          value={solarNetBilling}
                          // fieldName={"Net Billing"}
                          displayLabel={false}
                          setInputValue={setSolarNetBilling}
                          // handleErrors={handleErrors}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="w-full ml-[220px]">
                <InputBoxV1
                  placeholder="Net Metering"
                  id="test"
                  type="email"
                  // validationtypes={["required"]}
                  // showError={formSubmitted}
                  label={""}
                  value={solarNetMetering}
                  // fieldName={"Net Metering"}
                  setInputValue={setSolarNetMetering}
                  // handleErrors={handleErrors}
                />
              </div>{" "}
            </>
          )}
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Discom"}
              isflexed={true}
              // defaultValue={"Select Discom"}
              fieldName={"Discom"}
              validationtypes={["required"]}
              showError={formSubmitted}
              imputBoxMaxWidth={545}
              showLable={true}
              value={selectedDiscomCode}
              onChange={(e) => {
                const selectedDiscomCode = e.target.value;
                const selectedDiscom = discomCodeList.find(
                  (discom) => discom.code === selectedDiscomCode
                );
                if (selectedDiscom) {
                  setSelectedDiscomCode(selectedDiscomCode);
                  setSelectedStateCode(selectedDiscom.stateId.code);
                }
              }}
              options={discomCodeList.map((discom) => {
                const label = `${discom.name} (${discom.stateId.name})`;
                return { label, value: discom.code };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="w-full flex flex-row">
              <div className="py-[25px] pl-[30px] pb-[18px] p-0  font-normal text-[18px] leading-[22px] md:min-w-[212px] md:max-w-[212px] ">
                Location:
              </div>
            <button onClick={handleViewOnMapClick} className="text-blue-500 underline ml-4">
              View on Map
            </button>
          </div>
          <br />
          {/* Latitude Input */}
          <div className="w-[full]">
            <InputBoxV2
              placeholder="Enter latitude"
              id="latitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Latitude"
              value={latitude}
              fieldName="Latitude"
              setInputValue={setLatitude}
              handleErrors={handleErrors}
            />
          </div>
          {/* <br /> */}
          {/* Longitude Input */}
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter longitude"
              id="longitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Longitude"
              value={longitude}
              fieldName="Longitude"
              setInputValue={setLongitude}
              handleErrors={handleErrors}
            />
          </div>
          
          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[10px] sm:gap-[80px]">
            <Abutton
              handleOnclick={handleSubmit}
              text={"Save & Next"}
              Mheight={44}
              Mwidth={170}
              DHeight={44}
              DWidth={299}
            />
            <Link to={"/my-data"}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
      {isOverlayOpen && (
        <GooglePlacesMap
          address={address}
          onClose={handleOverlayClose}
          onMapClick={handleMapClick}
        />
      )}
    </div>
  );
};

export default EditUnit;
