import React, { useEffect, useState, useRef } from "react";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import LineChartEnergyView from "../../containers/graphs/LineChartEnergyView";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConstantUtils } from "../../utils/ConstantUtils";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";
import { useStateManager } from "react-select";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { useNavigate } from "react-router-dom";
const billCosmptionService = new BillConsumptionService();
const constantUtils = new ConstantUtils();
const CostDistrubutionView = ({
  selectedCostGraph,
  selectedCostYear,
  selectedCostMonth,
  selectedEnergyMeter,
  slotConfigData,
  selectedCostMeter,
  metersList
}) => {
  const options = {
    // indexAxis: 'y'
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          fontsize: 18,
          text: `${billCosmptionService.getFiscalYearLabel( parseInt(selectedCostYear) ) }  ${selectedCostMonth
            .toString()
            .toUpperCase()}`,
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Amount (Rs)",
        },

        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
      line: {
        tension: 0.4, // This will make the line curve
      },
    },

    responsive: true,
  };

  const [billDistrubutionData, setBillDistrubutionData] = useState({});
  const [isOpenDropwonView, setIsOpenDropwonView] = useState(false);
  const [billUrl, setBillUrl] = useState();
  const navigate = useNavigate();
  const handleEnergyMenuClick = () => {
    setIsOpenDropwonView(!isOpenDropwonView);
  };
  useEffect(() => {
    //    console.log(selectedCostGraph);
    //    console.log(selectedCostYear);
    //    console.log(selectedCostMonth);
  }, [selectedCostGraph]);

  const getGraphData = async () => {
    //    console.log({selectedCostYear,selectedCostMonth,selectedEnergyMeter})
    const data = await billCosmptionService.getMonthlyBillDistrubution(
      selectedCostYear.toString(),
      selectedCostMonth.toString(),
      selectedEnergyMeter
    );
    setBillDistrubutionData({
      data,
      selectedCostYear,
      selectedCostMonth,
      selectedEnergyMeter,
    });
    //    console.log({ BillDistrubutionData: data });
  };
  const [IsLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getGraphData();
  }, [selectedCostYear, selectedCostMonth, selectedEnergyMeter]);

  useEffect(() => {
    const selectedMeterData = metersList.filter((meter) => meter._id === selectedCostMeter);

    const getDateRange = getMonthDateRange(selectedCostMonth);
if(getDateRange && selectedMeterData && selectedMeterData[0]  && selectedMeterData[0]["consumerNo"]){
  console.log({billUrl:`/compare-ocr-bills?conNo=${selectedMeterData[0]["consumerNo"]}&startDate=${getDateRange['startDate']}&endDate=${getDateRange['endDate']}&redirect=analytics`});
  setBillUrl(`/compare-ocr-bills?conNo=${selectedMeterData[0]["consumerNo"]}&startDate=${getDateRange['startDate']}&endDate=${getDateRange['endDate']}&redirect=analytics`);
}
  }, [selectedCostMeter,selectedCostYear,selectedCostMonth])

  const getMonthDateRange = (month) => {
    const year = selectedCostYear;
    console.log({selectedCostYear})

    // Mapping for months to numerical values
    const monthMap = {
      JAN: "01",
      FEB: "02",
      MAR: "03",
      APR: "04",
      MAY: "05",
      JUN: "06",
      JUL: "07",
      AUG: "08",
      SEP: "09",
      OCT: "10",
      NOV: "11",
      DEC: "12"
    };

    // Get the month number from the map
    const monthNumber = monthMap[month.toUpperCase()];

    if (!monthNumber) {
      console.log("Invalid month format. Use the first three letters of the month (e.g., JAN, FEB).");
    }

    return {
      startDate: `${monthNumber}/${year}`,
      endDate: `${monthNumber}/${year}`
    };
  }

  useEffect(() => {
    if (
      billDistrubutionData.selectedCostYear === selectedCostYear &&
      billDistrubutionData.selectedCostMonth === selectedCostMonth &&
      billDistrubutionData.selectedEnergyMeter === selectedEnergyMeter
    ) {
        setIsLoading(true);
        console.log("true")
        setTimeout(() => {
            setIsLoading(false);

        }, 1200);
    }else{
        setIsLoading(true);
    }
  }, [
    billDistrubutionData,
    selectedCostYear,
    selectedCostMonth,
    selectedEnergyMeter,
  ]);

  const addValues = (originalArray) => {
    let resultantArray = [];
    let sum = 0;
    for (let i = 0; i < originalArray.length; i++) {
      sum += originalArray[i];
      resultantArray.push(sum);
    }
    return resultantArray;
  };
  const [data, setData] = useState({
    labels: [
      "Demand Charges",
      "Wheeling Charge",
      "Energy Charges",
      "TOD Tariff EC",
      "FAC",
      "Electricity Duty",
      "Bulk Consumption Rebate",
      "Tax on Sale",
      "Incremental Consumption Rebate",
      "Charges For Excess Demand",
      "Tax Collection at Source",
      "Debit Bill Adjustment",
      "Total Bill",
      "",
    ],
    datasets: [
      {
        type: "line",
        label: "Bill Amount",
        data: [
          1000, 5000, 8000, 14000, 10000, 20000, 30000, 25000, 24000, 26000,
          27000, 33000, 33000,
        ],
        backgroundColor: constantUtils.getLineGraphColors()[0],
        borderColor: constantUtils.getLineGraphColors()[0],
        borderWidth: 3,
        fill: true,
        stepped: true,
        order: -1,
        tension: 5,
      },
      {
        type: "bar",
        label: "charges",
        stepped: true,

        data: [
          1000, 4000, 3000, 6000, -4000, 10000, 10000, -5000, -1000, 2000, 1000,
          6000, 33000,
        ],
        backgroundColor: "orange",
        borderColor: "orange",
      },
    ],
  });

  const [full, setFull] = useState(false);

  const dropdownRefcs = useRef(null); // Add this line

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefcs.current &&
        !dropdownRefcs.current.contains(event.target)
      ) {
        setIsOpenDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const dataArray = [
      Math.floor(billDistrubutionData?.data?.data?.demandCharges?.value || 0),
      Math.floor(billDistrubutionData?.data?.data?.wheelingCharge?.value || 0),
      Math.floor(billDistrubutionData?.data?.data?.energyCharges?.value || 0),
      Math.floor(billDistrubutionData?.data?.data?.todTariffEc?.value || 0),
      Math.floor(billDistrubutionData?.data?.data?.fac?.value || 0),
      Math.floor(billDistrubutionData?.data?.data?.electricityDuty?.value || 0),
      Math.floor(
        billDistrubutionData?.data?.data?.bulkConsumptionRebate?.value || 0
      ),
      Math.floor(billDistrubutionData?.data?.data?.taxOnSale?.value || 0),
      Math.floor(
        billDistrubutionData?.data?.data?.incrementalConsumptionRebate?.value ||
          0
      ),
      Math.floor(
        billDistrubutionData?.data?.data?.chargesForExcessDemand?.value || 0
      ),
      Math.floor(
        billDistrubutionData?.data?.data?.taxCollectionAtSource?.value || 0
      ),
      Math.floor(
        billDistrubutionData?.data?.data?.debitBillAdjustment?.value || 0
      ),
    ];

    dataArray[12] = Math.floor(
      billDistrubutionData?.data?.data?.totalBillAmount?.value || 0
    );
    const resultArray = addValues(dataArray);
    resultArray[12] = Math.floor(
      billDistrubutionData?.data?.data?.totalBillAmount?.value || 0
    );
    setData((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: resultArray,
        },
        {
          ...prevState.datasets[1],
          type: "bar",
          label: "charges",
          backgroundColor: "orange",
          borderColor: "orange",
          data: dataArray,
        },
      ],
    }));
  }, [billDistrubutionData]);

  return (
    <div className="w-full flex justify-center">
      <div
        className={`${
          selectedCostGraph === "BillDistrubutionView" ? "flex" : "hidden"
        }  flex-col shadow-[0_4px_4px_0_#00000040] sm:w-[70%]  border border-[#00000040] rounded-[30px]  w-full`}
      >
        <div className={`px-[20px]  sm:px-[35px]`}>
          <div
            className={` flex justify-between items-center px-[35px]   py-[25px]  w-full`}
          >
            <div>Component Wise Bill Distribution </div>
      
            <div className="cursor-pointer flex gap-[20px]">
            <div onClick={()=>navigate(billUrl)} className="bg-green-700 text-[14px] py-1 px-2  text-white rounded-lg">
               View Bill
              </div>
              <button onClick={() => setFull(true)}>
                <FontAwesomeIcon icon={faExpand} />
              </button>
              <CustomTooltop
                disable={
                  constantUtils.getGraphToolTipData().MonthlyBillDistribution
                    .disableToolTip
                }
                text={
                  constantUtils.getGraphToolTipData().MonthlyBillDistribution
                    .text
                }
              />
            </div>
            {/*  <img
            onClick={handleEnergyMenuClick}
            alt="menuIcon"
            src="/graphMenuIcon.svg"
          />
          {isOpenDropwonView && (
            <div className="absolute right-[1%]">
              {isOpenDropwonView && (
                <div className={`absolute  pt-[25px]`}>
                  <ul
                    className={` text-[16px] rounded-[10px] bg-white  font-medium leading-[24px] border py-[10px] px-[10px] shadow-[0_4px_4px_0_#00000040] ml-[-400px] border-[#D9D9D9]`}
                  >
                    <li
                      onClick={() =>
                        handleCostGraph("Annual",)
                      }
                      className="inline-flex cursor-pointer gap-[10px]"
                    >
                      <img src="/leftCircleArrow.svg" />
                      <span>Annual Power Consumption View (KWH)</span>{" "}
                    </li>
                    <li
                      onClick={() =>
                        handleCostGraph(
                          "Monthly",
                          selectedCostYear,
                          selectedCostMonth
                        )
                      }
                      className="inline-flex cursor-pointer gap-[10px]"
                    >
                      <img src="/leftCircleArrow.svg" />
                      <span>Monthly Power Consumption View (KWH)</span>{" "}
                    </li>

                    <li
                      onClick={() =>
                        handleCostGraph(
                          "TOD",
                          selectedCostYear,
                          selectedCostMonth
                        )
                      }
                      className="inline-flex cursor-pointer gap-[10px]"
                    >
                      <img src="/leftCircleArrow.svg" />
                      <span>TOD Power Consumption View (KWH)</span>{" "}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )} */}
          </div>
          <div>
            <LegendComponent slotConfigData={slotConfigData} data={data} />
          </div>
        </div>
        {IsLoading?<div className="w-full sm:min-h-[400px] h-full flex justify-center items-center">
          <LoaderComponent />
        </div>:<div className=" px-[35px]   pb-[25px]">
          <LineChartEnergyView
            data={data}
            options={options}
            full={full}
            setFull={setFull}
          />
        </div>}
        
      </div>
    </div>
  );
};

export default CostDistrubutionView;
