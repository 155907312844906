import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";

const IncentivePopup = ({ setOpenIncentivePopup, openIncentivePopup, dashboardStats, savingsThroughGreenMeterWise, selectedRecommendationState, savingsThroughGreen, selectedRecommedationYear, startDate, endDate, activeTab, setActiveTab, filters }) => {
  // console.log(savingsThroughGreenMeterWise,'savingsThroughGreenMeterWise')
  // console.log(savingsThroughGreen,'savingsThroughGreen')
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // console.log(activeTab,'activeTab');
  useEffect(() => {
    // console.log(openPopUp)
    setIsOpen(openIncentivePopup);
    setActiveTab(0);
  }, [openIncentivePopup]);

  const formatDateWithMonth = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${month}-${year}`;
  };

  const formatDateToString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}/${year}`;
  };

  const getFinancialYearDates = (financialYear) => {
    const yearParts = financialYear.slice(2).split("-");
    const startYear = parseInt(yearParts[0]);
    const endYear = parseInt(yearParts[1]) + 2000; // Add 2000 to get the correct year format

    const startDate = `04/${startYear}`;
    const endDate = `03/${endYear}`;

    return { startDate, endDate };
  };

  const buildURL = () => {
    const dates = getFinancialYearDates(selectedRecommedationYear)
    let url = `/recommendation?org=${filters.organisationId}&startDate=${dates.startDate}&endDate=${dates.endDate}`;

    if (filters.stateCode) {
      url += `&state=${selectedRecommendationState}`;
    }

    if (filters.meterId && filters.meterId.length > 0) {
      url += `&consumers=[${filters.meterId}]`;
    }

    return url;
  };

  const url = buildURL();

  const handleRecommendationOpen = () => {
    navigate(`${url}`, {
      state: { savingsThroughGreenMeterWise }
    });
  }

  return (
    <div className="">
      {isOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center rounded-xl" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center text-center ">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" aria-hidden="true"></div>
            <div className="absolute align-bottom rounded-lg text-left mt-20">
              <div
                style={{ border: "1px solid #B9B9B9" }}
                className="mx-[20px] sm:mx-[89px] xl:m-auto sm:min-w-[700px] max-w-[800px] xl:w-full  drop-shadow-[0px_10px_5px_rgba(0,0,0,0.25)] rounded bg-[white]"
              >
                <div className="p-8">

                  <div className="absolute top-1 right-0 cursor-pointer" onClick={() => setOpenIncentivePopup(!openIncentivePopup)}>
                    <FontAwesomeIcon icon={faClose} className="text-[#000000] mr-2 text-xl font-medium transition-all ease-out" />
                  </div>

                  <div className="mt-4">



                    <div className="container mx-auto mt-4">
                      <table className="min-w-full border-collapse border border-gray-300">
                        <tbody>
                         

                          <tr>
                            <td className="px-4 py-2 bg-green-500 text-white">Total Early Payment Incentive</td>
                            <td className="px-4 py-2">
                              <span className="flex flex-row items-center">
                                <FontAwesomeIcon className="mr-1" icon={faIndianRupeeSign} />
                                {Number(dashboardStats?.totalEarlyPaymentIncentive)?.toLocaleString('en-IN') || 0}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td className="px-4 py-2 bg-green-500 text-white">Total Power Factor Incentive</td>
                            <td className="px-4 py-2">
                              <span className="flex flex-row items-center">
                                <FontAwesomeIcon className="mr-1" icon={faIndianRupeeSign} />
                                {Number(dashboardStats?.totalPowerFactorIncentive)?.toLocaleString('en-IN') || 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-4 py-2 bg-green-600 text-white font-bold border-t-2 ">Total Incentive</td>
                            <td className="px-4 py-2 bg-slate-300 font-bold border-t-2">
                              <span className="flex flex-row items-center ">
                                <FontAwesomeIcon className="mr-1" icon={faIndianRupeeSign} />
                                {Number(dashboardStats?.totalIncentiveSum?.value)?.toLocaleString('en-IN') || 0}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncentivePopup;
