import React, { useEffect, useState, useRef } from "react";
import LineChartEnergyView2Y from "../../containers/graphs/LineChartEnergyView2Y";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";
const constantUtils = new ConstantUtils();
const billCosmptionService = new BillConsumptionService();

const CostMonthlyView = ({
    costMonthlyData,
  selectedCostGraph,
  selectedCostYear,
  selectedCostMonth,
  costBillingDemand,
  selectedUnit,
  slotConfigData
}) => {
  const [isOpenDropwonView, setIsOpenDropwonView] = useState(false);
  const [costData, setCostData] = useState([]);
  const handleEnergyMenuClick = () => {
    setIsOpenDropwonView(!isOpenDropwonView);
  };

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },

      
 tooltip: {
    callbacks: {
      label: function (context) {
        const value = context.raw;
        return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');;
      },
    },
  },

    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,

          text: selectedCostYear,
        },
        position: "bottom",
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Amount(Rs)", // Add your label here
        },
        grid: {
          display: false,
        },
ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }



      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Rs/kwh", // Add your label here
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });
  const [lableValue, setLableValue] = useState(
    constantUtils.getAllStaticMonths()
  );
  const [labels, setLabels] = useState(
    constantUtils.getMonthsFromAprilFinancial(selectedCostYear)
  );

  useEffect(() => {
//    console.log({ selectedCostYear, selectedCostMonth });
  }, [selectedCostYear, selectedCostMonth]);

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        x: {
          ...prevOptions.scales.x,
          title: {
            ...prevOptions.scales.x.title,
            text: billCosmptionService.getFiscalYearLabel(parseInt(selectedCostYear) ),
          },
          position: "bottom",
        },
      },
    }));
  }, [selectedCostYear]);
//  console.log({ mohtlyCostData });
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Rs/KWH",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().percentContractDemand,
        backgroundColor: constantUtils.getGraphColors().percentContractDemand,
        fill: false,
      },
    ],
  });

  useEffect(() => {
    if (costBillingDemand && costBillingDemand.rsperkwh) {
     console.log({ costBillingDemand });
//      console.log({ costBillingDemand: costBillingDemand.rsperkwh });
      setData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => {
          if (dataset.label === "Rs/KWH") {
            return {
              ...dataset,
              data:
                costBillingDemand && costBillingDemand.rsperkwh
                  ? costBillingDemand.rsperkwh
                  : [],
            };
          }
          return dataset;
        }),
      }));
    }
  }, [costBillingDemand]);

  useEffect(() => {
//    // console.log({ energyMonthlyData:  energyMonthlyData[selectedCostYear]["energyMonthLyData"][selectedUnit].length });
    if (
        costMonthlyData !== undefined &&
        costMonthlyData[selectedCostYear] &&
        costMonthlyData[selectedCostYear][selectedUnit] &&
        costMonthlyData[selectedCostYear][selectedUnit]["costMonthLyData"] &&
        costMonthlyData[selectedCostYear][selectedUnit]["costMonthLyData"]
        .length > 0
    ) {
      if (Object.keys(costMonthlyData).length !== 0) {

        setData((prevData) => ({
          ...prevData,
          datasets: prevData.datasets.map((dataset) => {
            if (dataset.label === "Rs/KWH") {
              return dataset;
            } else {
              const index = prevData.datasets.indexOf(dataset);
              return costMonthlyData[selectedCostYear][selectedUnit][
                "costMonthLyData"
              ][index];
            }
          }),
        }));

        setData((prevData) => {
          let newDatasets = prevData.datasets
            .map((dataset) => {
              if (dataset) {
                if (dataset.label === "Rs/KWH") {
                  return dataset;
                }
              }
            })
            .filter(Boolean);

          let dynamicDatasets =
          costMonthlyData[selectedCostYear][selectedUnit][
              "costMonthLyData"
            ];

          newDatasets.push(...dynamicDatasets);

          return {
            ...prevData,
            datasets: newDatasets,
          };
        });
      }
    } else {
      setData((prevData) => {
        let newDatasets = prevData.datasets.filter((dataset) => {
          return dataset.label === "Rs/KWH";
        });

        return {
          ...prevData,
          datasets: newDatasets,
        };
      });
    }
  }, [costMonthlyData, selectedUnit, selectedCostYear]);

  useEffect(() => {
//    console.log(data);
  }, [data]);
  const [isLoading, setIsLoading] = useState(true);

  const [full, setFull] = useState(false);

  useEffect(() => {
//    console.log({ energyMonthlyData, selectedCostYear });
    if (
        costMonthlyData[selectedCostYear] !== undefined ||
        costMonthlyData !== undefined
    ) {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);

        }, 1200);
    } else {
      setIsLoading(true);
    }
  }, [costMonthlyData]);

  useEffect(() => {
    setLabels(constantUtils.getMonthsFromAprilFinancial(selectedCostYear));
  }, [selectedCostYear])
  
  
    useEffect(() => {
      setData((prevData) => ({
          ...prevData,
          labels: labels,
      }));
  }, [labels]);
  

  const dropdownRefCostMonth = useRef(null); // Add this line

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefCostMonth.current &&
        !dropdownRefCostMonth.current.contains(event.target)
      ) {
        setIsOpenDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${
        selectedCostGraph === "Monthly" ? "flex" : "hidden"
      }  flex-col shadow-[0_4px_4px_0_#00000040] sm:min-h-[400px] border border-[#00000040] rounded-[30px] w-full`}
    >
      <div className={`px-[20px] sm:px-[35px]`}>
        <div
          className={` flex  items-center justify-between px-[20px] sm:px-[35px] py-[10px] sm:py-[25px]  w-full`}
        >
          <div>Annual: ToD Cost Distribution : Discom  </div>

          <div className="cursor-pointer flex gap-[20px]">
            <button onClick={() => setFull(true)}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
            <CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().MonthlyCost.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().MonthlyCost.text}
            />
          </div>
          {/* <img onClick={handleEnergyMenuClick} alt="menuIcon" src="/graphMenuIcon.svg" />
          {isOpenDropwonView && (
            <div className="absolute right-[1%] " >
              {isOpenDropwonView && (
                <div className={`absolute  pt-[35px]`}>
                  <ul
                    className={` text-[16px] rounded-[10px] bg-white  font-medium leading-[24px] border py-[10px] px-[10px] shadow-[0_4px_4px_0_#00000040] ml-[-380px] border-[#D9D9D9]`}
                  >
                    <li
                      onClick={() => handleCostGraph("Annual", "2022", "")}
                      className="inline-flex cursor-pointer gap-[10px]"
                    >
                      <img src="/leftCircleArrow.svg" />
                      <span>Annual Power Consumption View (KWH)</span>{" "}
                    </li>
                  </ul>
                </div>
              )} 
            </div>
          )}*/}
        </div>
        <div><LegendComponent data={data} slotConfigData={slotConfigData} columns={"grid-cols-5"} /></div>
        
      </div>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="flex gap-1 justify-end py-2 px-5"> */}
          {/*     {" "}
            {labels.map((label) => (
              <button
                className="bg-gray-800 text-white font-bold text-[12px]  px-2 rounded"
                key={label}
                onClick={() =>
                  handleCostGraph("TOD", selectedCostYear, label.toLocaleLowerCase())
                }
              >
                {label}
              </button>
            ))} */}
          {/* </div> */}
          <div className="sm:px-[35px] pb-[20px] sm:py-[25px]">
            <LineChartEnergyView2Y
              data={data}
              options={options}
              full={full}
              setFull={setFull}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CostMonthlyView;
