import React, { useEffect, useRef } from 'react';
import loadGoogleMapsApi from '../../utils/googleMapApi';


const GoogleMapDashboard = ({ address }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null); // Store the map instance
  const markerRef = useRef([]);

  useEffect(() => {
    const initializeMap = async () => {
      try {
        await loadGoogleMapsApi();

        // Initialize map only if it hasn't been initialized yet
        if (!mapInstance.current) {
          mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: 23.0, lng: 80.9629 }, // Center on India
            zoom: 4.39, // Zoom level to show the entire country
            mapTypeControl: false,
            scrollwheel: false,
          });
        }

        if (address && address.length > 0) {
          // Clear existing markers before adding new ones
          markerRef.current.forEach(marker => marker.setMap(null));
          markerRef.current = [];

          // Add new markers for each address
          address.forEach((addr) => {
            const location = { lat: addr.coordinates[0], lng: addr.coordinates[1] };
            const marker = new window.google.maps.Marker({
              map: mapInstance.current, // Use the existing map instance
              position: location,
              title: addr.name,
            });
            markerRef.current.push(marker);
          });
        }
      } catch (error) {
        console.error('Error loading Google Maps API', error);
      }
    };

    // Initialize or update the map and markers
    initializeMap();

    return () => {
      // Cleanup markers when component unmounts or when address changes
      if (markerRef.current) {
        markerRef.current.forEach(marker => marker.setMap(null));
      }
    };
  }, [address]); // Only run when address changes

  return (
    <div className="bg-black bg-opacity-50 flex justify-center items-center z-50 relative w-[100%] h-[100%]">
      <div ref={mapRef} className="w-[100%] h-[100%]"></div>
    </div>
  );
};

export default GoogleMapDashboard;
