
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import CustomTooltop from '../../components/tooltips/CustomTooltop'
import { ConstantUtils } from '../../utils/ConstantUtils'
import LegendComponent from '../../components/chart/LegendComponent'
import LoaderComponent from '../../components/loaderCompent/LoaderComponent'
import LineChartEnergyView2Y from '../../containers/graphs/LineChartEnergyView2Y'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import LIneChartEnergyTOD from '../../containers/graphs/LIneChartEnergyTOD'
const constantUtils = new ConstantUtils();
const MultipalUnitsMonthlyCostView = ({multipleUnitsData}) => {

    const [isLoading, setIsLoading] = useState(false);
  const [selectedEnergyGraph, setSelectedEnergyGraph] = useState('Monthly');
  const [isOpenMonthlyEnergyDropwonView, setIsOpenMonthlyEnergyDropwonView] =
  useState(false);
  const [full, setFull] = useState(false);
  const dropdownRef = useRef(null); // Add this line

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenMonthlyEnergyDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return value === 0 ? "N/A" : value?.toLocaleString('en-IN');;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Amount(Rs)",
        },

        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k"; 
            }
            return value;
          },
        },
      }
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  });

  const [labels, setLabels] = useState(["JAN","FEB", "MARCH", "APRIL", "MAY", "JUL", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"]);
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Cost",
        data: [10,12,40,50,100,100,100,100,323,323,53,544],
        yAxisID: "y",
        type: "bar",
        borderColor: constantUtils.getGraphColors().contractDemand,
        backgroundColor: constantUtils.getGraphColors().contractDemand,
        fill: false,
      }
    ],
  });
  useEffect(() => {
    setLabels(multipleUnitsData["labels"]);
    setData((prevData) => ({
      ...prevData,
      labels: multipleUnitsData["labels"],
      datasets: prevData.datasets.map((dataset) => {
        if (dataset.label === "Cost") {
          return {
            ...dataset,
            data:
              multipleUnitsData && multipleUnitsData.costs
                ? multipleUnitsData.costs
                : [],
          };
        }
        return dataset;
      }),
    }));
  }, [multipleUnitsData]);
  return (
    <div
      className={`${
        selectedEnergyGraph === "Monthly" ? "flex" : "hidden"
      }  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040] sm:min-h-[400px] rounded-[30px] w-full`}
    >
      <div className={`px-[20px] sm:px-[35px]`}>
        <div className={` flex justify-between items-center  py-[10px] sm:py-[25px] w-full`}>
          <div>Annual: ToD Energy Distribution : Discom  </div>

          <div ref={dropdownRef} className="cursor-pointer flex gap-[20px]">
            <button onClick={() => setFull(true)}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
            <CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().MonthlyEnergy.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().MonthlyEnergy.text}
            />
          </div>

         
        </div>
        {/* <LegendComponent data={data} columns={"grid-cols-5"} slotConfigData={slotConfigData} /> */}
      </div>

      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          
          <div className="sm:px-[35px] pb-[20px] sm:py-[25px] w-full">
          <LIneChartEnergyTOD
                data={data}
                options={options}
            
              />
          </div>
        </>
      )}
    </div>
  )
}

export default MultipalUnitsMonthlyCostView